import React, {useState} from "react";
import {ProcessType} from "@process/types/process.types";
import {NumberFormat} from "@src/components";
import ProcessSummaryEstimateAccept from "@process/components/ProcessSummary/ProcessSummaryEstimateAccept.component";
import {Button, ButtonGroup, Dropdown, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faCheck, faXmark, faPen, faListRadio} from "@fortawesome/pro-light-svg-icons";
import {SweetAlertResult} from "sweetalert2";
import {toast} from "react-toastify";
import { acceptEstimateSwal, rejectEstimateSwal } from "@src/modules/estimate/utils/swal";
import {acceptEstimate, rejectEstimate} from "@estimate/utils/axios";
import {ProcessInvoiceNumberForm} from "@process/components";
import {EstimateStatusKeyEnum} from "@estimate/types/estimate.enums";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessSummaryProps {
}

const ProcessSummaryComponent: React.FC<ProcessSummaryProps> = () => {
    const [showProcessInvoiceNumberFormModal, setHhowProcessInvoiceNumberFormModal] = useState<boolean>(false);
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const acceptEstimateHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        acceptEstimateSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                acceptEstimate(process.estimate.id).then(() => {
                    toast.success("Kosztorys został zaakceptowany.");
                    dispatch(refreshProcess());
                });
            }
        })
    }

    const rejectEstimateHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        rejectEstimateSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                rejectEstimate(process.estimate.id).then(() => {
                    toast.success("Kosztorys został odrzucony.");
                    dispatch(refreshProcess());
                });
            }
        })
    }

    const afterProcessInvoiceNumberSave = () => {
        setHhowProcessInvoiceNumberFormModal(false);
        dispatch(refreshProcess());
    }

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Koszt:</dt>
                <dd className="d-flex gap-3 justify-content-between">
                    <NumberFormat value={process.totalPriceGross}/>
                    {(process.estimate && parseFloat(process.totalPriceGross) > 0) && (
                        <ButtonGroup size={"sm"}>
                            {process.estimate.status.key !== EstimateStatusKeyEnum.Accepted && (
                                <Button size={"sm"} variant={"ghost-primary"}
                                    onClick={acceptEstimateHandler}
                                >
                                    <><FontAwesomeIcon icon={faCheck} fixedWidth={true}/> akceptacja wyceny</>
                                </Button>
                            )}

                            {process.estimate.status.key !== EstimateStatusKeyEnum.Rejected && (
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="outline-primary" size={"sm"}>
                                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={rejectEstimateHandler}
                                        >
                                            <FontAwesomeIcon icon={faXmark} fixedWidth /> Odrzucenie wyceny/rezygnacja
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </ButtonGroup>
                    )}
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Akceptacja wyceny:</dt>
                <dd>
                    <ProcessSummaryEstimateAccept estimate={process.estimate}/>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Status płatności:</dt>
                <dd>{process?.paymentStatus ?? ''}</dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Data płatności:</dt>
                <dd>{process.paidAt}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Numer FV/paragonu:</dt>
                <dd className="d-flex justify-content-between">
                    <span>{process.invoiceNumber}</span>
                    <Button size={"sm"} variant={"ghost-primary"}
                            onClick={() => setHhowProcessInvoiceNumberFormModal(true)}
                    >
                        <><FontAwesomeIcon icon={faPen} fixedWidth={true}/></>
                    </Button>

                    {showProcessInvoiceNumberFormModal && (
                        <Modal show={showProcessInvoiceNumberFormModal} onHide={() => setHhowProcessInvoiceNumberFormModal(false)}
                               size={"lg"} centered={true}
                               className="modal-blur"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title as="h5"><FontAwesomeIcon icon={faListRadio} fixedWidth={true}/> Numer faktury lub paragonu</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ProcessInvoiceNumberForm
                                    process={process} afterSave={afterProcessInvoiceNumberSave}
                                />
                            </Modal.Body>
                        </Modal>
                    )}
                </dd>
            </dl>
        </>
    );
};

export default ProcessSummaryComponent;