export enum ProcessDictionaryEnum {
    ProcessStatusSalon = 'ProcessStatusSalon',
    ProcessStatusService = 'ProcessStatusService',
}

export enum ProcessSalonStatusKeyEnum {
    New = 'new',
    ReceiveByMobile = 'receive_by_mobile',
    ReceiveByCourier = 'receive_by_courier',
    AcceptedInPoint = 'accepted_in_point',
    InService = 'in_service',
    EstimateToAccept = 'estimate_to_accept',
    ToPayment = 'to_payment',
    Paid = 'paid',
    SentToClient = 'sent_to_client',
    ReadyToPickup = 'ready_to_pickup',
    Done = 'done',
}