import React, {useEffect} from "react";
import {Login} from "@auth/components";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {auth, checkLogin} from "@auth/store/authSlice";
import {AppDispatch} from "@src/store";

const LoginView:React.FC = () => {
    const authData = useSelector(auth)
    const locationParams = new URLSearchParams(useLocation().search)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        let token = null;
        if (locationParams.has('token')) {
            token = locationParams.get('token');
            dispatch(checkLogin(token));
        } else {
            token = window.localStorage.getItem('auth.token')
            if (token) {
                dispatch(checkLogin(token));
            }
        };
    }, [])

    if (true === authData.isCheckLogin) {
        return (
            <div className="container container-tight- py-4 view-login">
                <h2 className="h2 text-center mb-4">Logowanie ...</h2>
            </div>
        );
    }

    return (
        <div className="container container-tight py-4 view-login">
            <div className="card card-md">
                <div className="card-body">
                    <h2 className="h2 text-center mb-4">Logowanie</h2>
                    <div className="mb-5">
                        <Login/>
                    </div>

                    <Link to="/recover-password">
                        Odzyskaj hasło &raquo;
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoginView;