import React, {useEffect, useState} from "react";
import {Dropdown, Form, Modal} from "react-bootstrap";
import {ProcessItemPartType, ProcessItemType, ProcessType} from "@process/types/process.types";
import styles from './ProcessItemPart.module.scss';
import {deleteProcessItemPart, updateProcessItemPart} from "@process/utils/axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faPen} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {faListRadio, faTrash} from "@fortawesome/pro-light-svg-icons";
import {faSort} from "@fortawesome/pro-thin-svg-icons";
import {mapProcessItemPartToRequestType} from "@process/utils/mapper";
import {ProcessItemPartEdit} from "@process/components";
import {deleteProcessItemPartSwal} from "@process/utils/swal";
import {SweetAlertResult} from "sweetalert2";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessItemPartProp {
    processItemPart: ProcessItemPartType,
    index: number,
    processItem: ProcessItemType,
}

export const ProcessItemPartComponent: React.FC<ProcessItemPartProp> = ({
    processItemPart: initProcessItemPart, index, processItem
}) => {
    const [processItemPart, setProcessItemPart] = React.useState<ProcessItemPartType>({...initProcessItemPart});
    const [showProcessItemPartEditModal, setProcessItemPartEditModal] = useState<boolean>(false);
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const onChangeAttachHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProcessItemPart(prevState => {
            const newState: ProcessItemPartType = {...prevState, isAttach: e.target.checked};
            saveProcessItemPart(newState);

            return newState;
        });
    }

    const onChangeNoteHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProcessItemPart(prevState => {
            return {...prevState, note: e.target.value};
        });
    }

    const onBlurNoteHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        saveProcessItemPart(processItemPart);
    }

    const afterProcessItemPartSuccessSave = () => {
        setProcessItemPartEditModal(false);
        dispatch(refreshProcess());
    }

    const saveProcessItemPart = (processItemPart: ProcessItemPartType) => {
        updateProcessItemPart(process.id, processItem.id, processItemPart.id, mapProcessItemPartToRequestType(processItemPart));
    }

    const onDeleteProcessItemPartHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        deleteProcessItemPartSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                deleteProcessItemPart(process.id, processItem.id, processItemPart.id).then(() => {
                    dispatch(refreshProcess());
                })
            }
        })
    }

    useEffect(() => {
        setProcessItemPart(initProcessItemPart)
    }, [initProcessItemPart]);

    return (
        <tr className={styles.partRow}>
            <td className="text-nowrap">
                <div className="item-handle-sort" style={{cursor: "move"}}>
                    <FontAwesomeIcon icon={faSort} fixedWidth={true} />
                    {index + 1}
                </div>
            </td>
            <td align="center">
                <Form.Check
                    type="switch"
                    name="isAttach"
                    id={`part-${processItemPart.id}`}
                    checked={processItemPart.isAttach}
                    onChange={onChangeAttachHandler}
                />
            </td>
            <td>
                <label htmlFor={`part-${processItemPart.id}`}>
                    {processItemPart.name}
                </label>
            </td>

            <td>
                <Form.Control
                    name="note"
                    type="text"
                    size="sm"
                    value={processItemPart.note ?? ''}
                    onChange={onChangeNoteHandler}
                    onBlur={onBlurNoteHandler}
                    style={{minWidth: 150}}
                />
            </td>

            <td>
                <Dropdown>
                    <Dropdown.Toggle variant={"outline-cyan"}>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setProcessItemPartEditModal(true)}>
                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Link} to="#" className="text-danger" onClick={onDeleteProcessItemPartHandler}>
                            <FontAwesomeIcon icon={faTrash} fixedWidth/> Usuń
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Modal show={showProcessItemPartEditModal} onHide={() => setProcessItemPartEditModal(false)}
                       size={"lg"} centered={true}
                       className="modal-blur"
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">
                            <FontAwesomeIcon icon={faListRadio} fixedWidth={true}/> Edycja elementu
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProcessItemPartEdit
                            process={process}
                            processItem={processItem}
                            processItemPart={processItemPart}
                            afterSuccessSave={afterProcessItemPartSuccessSave}
                        />
                    </Modal.Body>
                </Modal>
            </td>
        </tr>
    );
};

export default ProcessItemPartComponent;