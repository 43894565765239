import React, {ReactElement} from "react";
import {ProcessItemType, ProcessType} from "@process/types/process.types";
import {ProcessItemSubstituteRequestType} from "@process/types/processItem.types";
import {ProcessItemSubstituteForm} from "@process/components";
import {AxiosResponse} from "axios";
import {updateProcessItemSubstitute} from "@process/utils/axios/processItem";


interface ProcessItemSubstituteEditProps {
    process: ProcessType
    processItem: ProcessItemType,
    afterSuccessSave: Function,
}


const ProcessItemSubstituteEditComponent: React.FC<ProcessItemSubstituteEditProps> = ({process, processItem, afterSuccessSave}): ReactElement => {

    const saveProcessItemSubstitute = async (data: ProcessItemSubstituteRequestType): Promise<AxiosResponse> => {
        return updateProcessItemSubstitute(process.id, processItem.id, data);
    }

    return (
        <ProcessItemSubstituteForm
            processItemSubstitute={processItem.substitute}
            saveProcessItemSubstitute={saveProcessItemSubstitute}
            afterSuccessSave={afterSuccessSave}
        />
    );
}

export default ProcessItemSubstituteEditComponent;
