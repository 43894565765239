import React from "react";


const TestColorView: React.FC = () => {


    return (
        <>
            <h1>Test color</h1>

            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Standard Buttons</h3>
                        </div>
                        <div className="card-body">
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Normal</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-primary w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-secondary w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-success w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-warning w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-danger w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-info w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-dark w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-light w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Active state</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-primary active w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-secondary active w-100">
                                        Secondary
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-success active w-100">
                                        Success
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-warning active w-100">
                                        Warning
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-danger active w-100">
                                        Danger
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-info active w-100">
                                        Info
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-dark active w-100">
                                        Dark
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-light active w-100">
                                        Light
                                    </a>
                                </div>
                            </div>

                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Disabled</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-primary disabled w-100">
                                        Primary
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-secondary disabled w-100">
                                        Secondary
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-success disabled w-100">
                                        Success
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-warning disabled w-100">
                                        Warning
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-danger disabled w-100">
                                        Danger
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-info disabled w-100">
                                        Info
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-dark disabled w-100">
                                        Dark
                                    </a>
                                </div>

                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-light disabled w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Outline Buttons</h3>
                        </div>
                        <div className="card-body">
                            <p>Use <code>.btn-outline-*</code> class for outline buttons.
                            </p>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Normal</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-primary w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-secondary w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-success w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-warning w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-danger w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-info w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-dark w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-outline-light w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Active state</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-primary active w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-secondary active w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-success active w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-warning active w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-danger active w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-info active w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-dark active w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-outline-light active w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Disabled</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-primary disabled w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-secondary disabled w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-success disabled w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-warning disabled w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-danger disabled w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-info disabled w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-outline-dark disabled w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-outline-light disabled w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Ghost Buttons</h3>
                        </div>
                        <div className="card-body">
                            <p>Use <code>.btn-ghost-*</code> class for ghost buttons.
                            </p>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Normal</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-primary w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-secondary w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-success w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-warning w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-danger w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-info w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-dark w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-ghost-light w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Active state</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-primary active w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-secondary active w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-success active w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-warning active w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-danger active w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-info active w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-dark active w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-ghost-light active w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Disabled</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-primary disabled w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-secondary disabled w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-success disabled w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-warning disabled w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-danger disabled w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-info disabled w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-ghost-dark disabled w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-ghost-light disabled w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Square Buttons</h3>
                        </div>
                        <div className="card-body">
                            <p>Use <code>.btn-square</code> class for square buttons.
                            </p>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Normal</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-primary btn-square w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-secondary btn-square w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-success btn-square w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-warning btn-square w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-danger btn-square w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-info btn-square w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-dark btn-square w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-light btn-square w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Pill Buttons</h3>
                        </div>
                        <div className="card-body">
                            <p>Use <code>.btn-pill</code> class for pill buttons.
                            </p>
                            <div className="row g-2 align-items-center">
                                <div className="col-12 col-xl-2 font-weight-semibold">Normal</div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-primary btn-pill w-100">
                                        Primary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-secondary btn-pill w-100">
                                        Secondary
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-success btn-pill w-100">
                                        Success
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-warning btn-pill w-100">
                                        Warning
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-danger btn-pill w-100">
                                        Danger
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-info btn-pill w-100">
                                        Info
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl py-3">
                                    <a href="#" className="btn btn-dark btn-pill w-100">
                                        Dark
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl bg-dark py-3">
                                    <a href="#" className="btn btn-light btn-pill w-100">
                                        Light
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Extra colors</h3>
                        </div>
                        <div className="card-body">
                            <div className="row g-2 align-items-center">
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-blue w-100">
                                        Blue
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-azure w-100">
                                        Azure
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-indigo w-100">
                                        Indigo
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-purple w-100">
                                        Purple
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-pink w-100">
                                        Pink
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-red w-100">
                                        Red
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-orange w-100">
                                        Orange
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-yellow w-100">
                                        Yellow
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-lime w-100">
                                        Lime
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-green w-100">
                                        Green
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-teal w-100">
                                        Teal
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-cyan w-100">
                                        Cyan
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Icon buttons</h3>
                        </div>
                        <div className="card-body">
                            <div className="row g-2 align-items-center">
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-x w-100 btn-icon" aria-label="X">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-facebook w-100 btn-icon" aria-label="Facebook">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-twitter w-100 btn-icon" aria-label="Twitter">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-google w-100 btn-icon" aria-label="Google">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-youtube w-100 btn-icon" aria-label="Youtube">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-vimeo w-100 btn-icon" aria-label="Vimeo">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-dribbble w-100 btn-icon" aria-label="Dribbble">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-github w-100 btn-icon" aria-label="Github">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-instagram w-100 btn-icon" aria-label="Instagram">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-pinterest w-100 btn-icon" aria-label="Pinterest">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-vk w-100 btn-icon" aria-label="VK">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-rss w-100 btn-icon" aria-label="RSS">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-flickr w-100 btn-icon" aria-label="Flickr">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-bitbucket w-100 btn-icon" aria-label="Bitbucket">
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-tabler w-100 btn-icon" aria-label="Tabler">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Social colors</h3>
                        </div>
                        <div className="card-body">
                            <div className="row g-2 align-items-center">
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-x w-100">
                                        X
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-facebook w-100">
                                        Facebook
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-twitter w-100">
                                        Twitter
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-google w-100">
                                        Google
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-youtube w-100">
                                        Youtube
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-vimeo w-100">
                                        Vimeo
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-dribbble w-100">
                                        Dribbble
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-github w-100">
                                        Github
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-instagram w-100">
                                        Instagram
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-pinterest w-100">
                                        Pinterest
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-vk w-100">
                                        VK
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-rss w-100">
                                        RSS
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-flickr w-100">
                                        Flickr
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-bitbucket w-100">
                                        Bitbucket
                                    </a>
                                </div>
                                <div className="col-6 col-sm-4 col-md-2 col-xl-auto py-3">
                                    <a href="#" className="btn btn-tabler w-100">
                                        Tabler
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default TestColorView;
