import React, {useEffect, useState} from "react";
import {ProcessType} from "@process/types/process.types";
import {EstimateCreateComponent} from "@estimate/components";
import {EstimateFilterType, EstimateType} from "@estimate/types/estimate.types";
import {getEstimateList} from "@estimate/utils/axios";
import {toast} from "react-toastify";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faRefresh} from "@fortawesome/pro-regular-svg-icons";
import {ProcessEstimationListItemComponent as Item} from "./ProcessEstimationListItem.component" ;
import {Button, Card, Modal} from "react-bootstrap";
import {faScrewdriverWrench} from "@fortawesome/pro-light-svg-icons";
import {ProcessEstimateFormCommon} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessEstimateListProps {
}

export const ProcessEstimateListComponent: React.FC<ProcessEstimateListProps> = () => {
    const [estimateList, setEstimateList] = React.useState<EstimateType[]>([]);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [showEstimateCreateModal, setEstimateCreateModal] = useState<boolean>(false);
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const fetchList = () => {
        const estimateFilter: EstimateFilterType = {
            process: process.id
        };

        setLoading(true);
        getEstimateList(estimateFilter)
            .then((response: EstimateType[]) => setEstimateList(response))
            .finally(() => setLoading(false))
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    const refreshProcessEstimate = () => {
        dispatch(refreshProcess());
        fetchList();
    }

    useEffect(() => {
        fetchList();
    }, []);

    const afterSuccessSave = () => {
        setEstimateCreateModal(false);
        fetchList();
        dispatch(refreshProcess());
    }

    return (
        <>
            <Button variant={"outline-primary"} className={"mb-3"}
                    onClick={e => setEstimateCreateModal(true)}
            >
                <FontAwesomeIcon icon={faPlusCircle} fixedWidth={true}/> Nowy kosztorys
            </Button>
            <Card>
                <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                    <thead>
                    <tr>
                        <th>Data</th>
                        <th>Tytuł</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Dodany przez</th>
                        <th className="text-end">Wartość</th>
                        <th className="text-center">Data akceptacji</th>
                        <th className="text-end" style={{width: '0'}}>
                            <FontAwesomeIcon icon={faRefresh} fixedWidth={true} onClick={fetchList} spin={isLoading}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {estimateList.map((estimate: EstimateType) => (
                        <Item key={estimate.id} estimate={estimate} process={process}
                              fetchList={refreshProcessEstimate}/>
                    ))}
                    </tbody>
                </Table>
            </Card>

            <Modal show={showEstimateCreateModal} onHide={() => setEstimateCreateModal(false)}
                   size={"lg"} centered={true}
                   className="modal-blur"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5"><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Nowy kosztorys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProcessEstimateFormCommon process={process}/>
                    <EstimateCreateComponent
                        process={process}
                        afterSuccessSave={afterSuccessSave}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
};

export default ProcessEstimateListComponent;