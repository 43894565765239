import React, {ReactElement, useEffect} from "react";
import FilterComponent from "@src/components/Filter/Filter.component";
import FilterSearch from "@src/components/Filter/components/FilterSearch/FilterSearch.component";
import {Col, Row} from "react-bootstrap";
import {ProcessFilterType} from "@process/types/process.types";
import {UserFilterRequestType} from "@user/types/user.types";
import {mapUserFilterRequestToUserFilterType as mapUserFilter} from "@user/utils/mapper";

interface UserFilterProps {
    userFilterRequest: UserFilterRequestType
}

const UserFilter:React.FC<UserFilterProps> = ({userFilterRequest}): ReactElement => {
    const [filter, setFilter] = React.useState<ProcessFilterType>(mapUserFilter(userFilterRequest));
    useEffect(() => setFilter(mapUserFilter(userFilterRequest)), [userFilterRequest]);

    return (
        <FilterComponent>
            <Row className="pb-3">
                <Col lg="3" md={6} xs={12}>
                    <FilterSearch value={filter.search ?? ''} />
                </Col>
            </Row>
        </FilterComponent>
    );
}

export default UserFilter;