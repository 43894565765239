import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {
    ProcessType,
    ProcessFormType,
    ProcessInitRequest,
    ProcessItemPartType,
    ProcessFileType,
    ProcessFileFormType,
    ProcessFileFilterType,
    ProcessFilterRequestType,
    ProcessItemPartRequestType,
    ProcessItemPartPositionRequestType, ProcessStatusAssignRequestType, ProcessInvoiceNumberRequestType
} from "@process/types/process.types";
import {Maybe, PaginationDataType} from "@src/types/global.types";

export const initProcess = async (requestBody?: ProcessInitRequest): Promise<ProcessType> => {
    const {data} = await clientAxios.post<ProcessInitRequest, AxiosResponse<ProcessType>>('/api/v1/process/init', requestBody);
    return data;
}

export const getProcessCollection = async (filter: ProcessFilterRequestType): Promise<PaginationDataType<ProcessType>> => {
    const {data} = await clientAxios.post<ProcessFilterRequestType, AxiosResponse<PaginationDataType<ProcessType>>>('/api/v1/process', filter);
    return data;
}

export const getProcess = async (id: string): Promise<ProcessType> => {
    const {data} = await clientAxios.get<never, AxiosResponse<ProcessType>>('/api/v1/process/' + id);
    return data;
}

export const createProcess = async (data: ProcessFormType) => {
    return await clientAxios.post<ProcessFormType, AxiosResponse>('/api/v1/process/create', data);
}

export const updateProcess = async (id: string, data: ProcessFormType) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/process/update/' + id, data);
}

export const initProcessItemPart = async (processId: string, processItemId: string): Promise<AxiosResponse> => {
    return await clientAxios.post<never, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/init`);
}

export const createProcessItemPart = async (processId: string, processItemId: string, part: ProcessItemPartRequestType): Promise<AxiosResponse> => {
    return await clientAxios.post<ProcessItemPartType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/create`, part);
}

export const updateProcessItemPart = async (
    processId: string, processItemId: string, processItemPartId: string, part: ProcessItemPartRequestType
): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessItemPartType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/${processItemPartId}/update`, part);
}

export const deleteProcessItemPart = async (
    processId: string, processItemId: string, processItemPartId: string
): Promise<AxiosResponse> => {
    return await clientAxios.delete<never, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/${processItemPartId}/delete`);
}

export const positionProcessItemPart = async (
    processId: string, processItemId: string, data: ProcessItemPartPositionRequestType
): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessItemPartPositionRequestType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/part/position`, data);
}

export const createProcessFileEntry = async (processId: string) => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry');
}

export const updateProcessFileEntry = async (processId: string) => {
    return await clientAxios.patch<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry');
}

export const downloadProcessFileEntry = async (processId: string) => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/process/' + processId + '/file/entry/download', {
        responseType: 'blob',
    });
}

export const fetchProcessFiles = async (processId: string, filter?: Maybe<ProcessFileFilterType>) => {
    return await clientAxios.post<ProcessFileFilterType, AxiosResponse<ProcessFileType[]>>('/api/v1/process/' + processId + '/file', filter);
}

export const uploadProcessFiles = async (processId: string, formData: FormData) => {
    return await clientAxios.post<FormData, AxiosResponse>('/api/v1/process/' + processId + '/file/upload', formData);
}

export const updateProcessFile = async (
    processId: string,
    fileId: string,
    data: ProcessFileFormType,
): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessFileFormType, AxiosResponse>('/api/v1/process/' + processId + '/file/' + fileId, data);
}

export const sendCreatedEmail = async (processId: string): Promise<AxiosResponse> => {
    return await clientAxios.post<never, AxiosResponse>('/api/v1/process/' + processId + '/email/send-created');
}

export const assignProcessEstimate = async (processId: string, estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.patch<never, AxiosResponse>(`/api/v1/process/${processId}/estimate/${estimateId}/assign`);
}

export const assignProcessStatus = async (processId: string, data: ProcessStatusAssignRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessStatusAssignRequestType, AxiosResponse>(`/api/v1/process/${processId}/status-assign`, data);
}

export const updateProcessInvoiceNumber = async (processId: string, data: ProcessInvoiceNumberRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessInvoiceNumberRequestType, AxiosResponse>(`/api/v1/process/${processId}/invoice-number`, data);
}

