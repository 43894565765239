import Swal, {SweetAlertOptions, SweetAlertResult} from "sweetalert2";

export const archiveFileSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy zarchiwizować plik?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, archiwizuj!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const sendCreatedEmailSwal = (options?: SweetAlertOptions) => {
    return Swal.fire({
        title: 'Czy wysłać powiadomienie e-mail o przyjęciu zgłoszenia do serwisu?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, wyślij!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const deleteProcessItemPartSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy usunąć element?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, usuń!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const assignProcessEstimateSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy ustawić kosztorys jako główny dla wyceny serwisu?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}
