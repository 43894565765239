import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import {ClientType} from "@client/types/client.types";
import { Card } from 'react-bootstrap';

interface ClientCardProps{
    client: ClientType
}

const ClientCardComponent:React.FC<ClientCardProps> = ({client}) => {

    return (
        // <Card>
            <Table bordered className="card-table-" >
                <tbody>
                <tr>
                    <td scope="col-1" className={"text-end"}></td>
                    <td scope="col-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="m-0">{client.firstName} {client.lastName}</h4>
                            <Link to={`/client/edit/${client.id}`}>
                                <FontAwesomeIcon icon={faPencil}/> edytuj
                            </Link>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="text-end">Firma:</td>
                    <td>{client.companyName}</td>
                </tr>
                <tr>
                    <td className="text-end">NIP:</td>
                    <td>{client.nip}</td>
                </tr>
                <tr>
                    <td className="text-end">Status:</td>
                    <td>{client.status.name}</td>
                </tr>
                <tr>
                    <td className="text-end">Telefon:</td>
                    <td>{client.phone}</td>
                </tr>
                <tr>
                    <td className="text-end">E-mail:</td>
                    <td>{client.email}</td>
                </tr>
                <tr>
                    <td className="text-end">Adres:</td>
                    <td>
                        <div>{client.street}</div>
                        <div>{client.postCode + " " + client.city}</div>
                    </td>
                </tr>
                </tbody>
            </Table>
        // </Card>
    );
}

export default ClientCardComponent;