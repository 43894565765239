import React, {ReactElement, useEffect, useState, useRef} from "react";
import {
    ProcessItemType,
    ProcessItemPartType,
    ProcessType,
} from "@process/types/process.types";
import {Alert, Button, ButtonGroup, Card, Col, Dropdown, Modal, Row} from "react-bootstrap";
import {
    createProcessFileEntry,
    downloadProcessFileEntry,
    initProcessItemPart, positionProcessItemPart,
    updateProcessFileEntry
} from "@process/utils/axios";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEllipsis,
    faArrowsRotate,
    faSpinner,
    faListRadio,
    faScrewdriverWrench
} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import {
    ProcessEstimateFormCommon,
    ProcessItemPart as ProcessItemPartComponent,
    ProcessItemPartCreate,
    ProcessItemSubstitute
} from "..";
import {faDownload, faPlusCircle, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {downloadFile} from "@src/helpers/utils.helper";
import {AxiosResponse} from "axios";
import {ReactSortable} from "react-sortablejs";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessItemProps {
    processItem: ProcessItemType;
}

const ProcessItemComponent: React.FC<ProcessItemProps> = ({processItem}): ReactElement => {

    const [initParts, setInitParts] = React.useState<boolean>(false);
    const [isCreatingFileEntry, setCreatingFileEntry] = React.useState<boolean>(false);
    const [isUpdatingFileEntry, setUpdatingFileEntry] = React.useState<boolean>(false);
    const [isDownloadingFileEntry, setDownloadingFileEntry] = React.useState<boolean>(false);
    const [showProcessItemPartCreateModal, setProcessItemPartCreateModal] = useState<boolean>(false);
    const [processItemPartList, setProcessItemPartList] = useState<any[]>([]);
    const sortRef = useRef(null);
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const initProcessItemPartHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setInitParts(true);

        initProcessItemPart(process.id, processItem.id).then(() => {
            toast.success("Zapisano");
            dispatch(refreshProcess());
        })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
                setInitParts(false);
            })
    }

    const createProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isCreatingFileEntry) {
            return;
        }
        setCreatingFileEntry(true);

        createProcessFileEntry(process.id)
            .then(() => {
                toast.success("Zapisano");
                dispatch(refreshProcess())
            })
            .finally(() => {
                setCreatingFileEntry(false);
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    const downloadProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isDownloadingFileEntry) {
            return;
        }
        setDownloadingFileEntry(true);

        downloadProcessFileEntry(process.id)
            .then((response: AxiosResponse<Blob>) => {
                const fileName = response.headers["content-disposition"]?.split(";")[1]?.replace(/filename=/g, "")
                    ?? process.fileEntry.file.filename;

                downloadFile(response.data, fileName);
            })
            .finally(() => {
                setDownloadingFileEntry(false);
            })
    }

    const updateProcessFileEntryHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (isUpdatingFileEntry) {
            return;
        }
        setUpdatingFileEntry(true);

        updateProcessFileEntry(process.id)
            .then(() => {
                toast.success("Zapisano");
                dispatch(refreshProcess())
            })
            .finally(() => {
                setUpdatingFileEntry(false);
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    const afterProcessItemPartSuccessSave = () => {
        setProcessItemPartCreateModal(false);
        dispatch(refreshProcess());
    }

    const onProcessItemPartSetHandler = (newState) => {
        let prevList: any[] = [];
        let nextList: any[] = [];

        processItemPartList.map(item => prevList.push({id: item.id}));
        newState.map(item => nextList.push({id: item.id}));
        setProcessItemPartList(newState)

        if (JSON.stringify(prevList) !== JSON.stringify(nextList)) {
            positionProcessItemPart(process.id, processItem.id, {processItemParts: nextList});
        }
    }

    useEffect(() => {
        setProcessItemPartList(processItem.parts.map(x => ({ ...x, chosen: true })));
    }, [processItem.parts]);

    return (
        <div>
            <Row>
                <Col lg={6}>
                    <dl className="dl-horizontal">
                        <dt>Protokół przyjęcia:</dt>
                        <dd>
                            {process.fileEntry ? (
                                <div>
                                    <ButtonGroup size={"sm"}>
                                        <Button size={"sm"} variant={"outline-primary"}
                                                onClick={downloadProcessFileEntryHandler}
                                                disabled={isDownloadingFileEntry}
                                        >
                                            {isDownloadingFileEntry ? (
                                                <><FontAwesomeIcon icon={faSpinner} fixedWidth={true}/> pobieram protokół...</>
                                            ) : (
                                                <><FontAwesomeIcon icon={faDownload} fixedWidth={true}/> pobierz protokół</>
                                            )}

                                        </Button>

                                        <Dropdown as={ButtonGroup}>
                                            <Dropdown.Toggle variant="outline-primary" size={"sm"}>
                                                <FontAwesomeIcon icon={faEllipsis}/>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    disabled={isUpdatingFileEntry}
                                                    onClick={updateProcessFileEntryHandler}
                                                >
                                                    <FontAwesomeIcon icon={faArrowsRotate} fixedWidth/> Aktualizuj protokół
                                                </Dropdown.Item>
                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </ButtonGroup>
                                    &nbsp;
                                    <em className="text-muted small">
                                        Data zapisu: {process.fileEntry.file.updatedAt}
                                    </em>
                                </div>
                            ) : (
                                <Button size={"sm"} variant={"outline-primary"}
                                        onClick={createProcessFileEntryHandler}
                                >
                                    {isCreatingFileEntry ? (
                                        <><FontAwesomeIcon icon={faSpinner} fixedWidth={true}/> generuję protokół...</>
                                    ) : (
                                        <><FontAwesomeIcon icon={faDownload} fixedWidth={true}/> generuj protokół przyjęcia</>
                                    )}
                                </Button>
                            )}
                        </dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>Nazwa:</dt>
                        <dd>{processItem.name}</dd>
                    </dl>
                    {processItem.brand && (
                        <dl className="dl-horizontal">
                            <dt>Marka:</dt>
                            <dd>{processItem.brand}</dd>
                        </dl>
                    )}
                    {processItem.model && (
                        <dl className="dl-horizontal">
                            <dt>Model:</dt>
                            <dd>{processItem.model}</dd>
                        </dl>
                    )}
                    <dl className="dl-horizontal">
                        <dt>Numer seryjny:</dt>
                        <dd>{processItem.serialNumber}</dd>
                    </dl>
                    <dl className="dl-horizontal border-0">
                        <dt>Opis ustarki:</dt>
                        <dd>
                            {!processItem.note ? (
                                <span className="text-muted">- - -</span>
                            ) : (
                                <em style={{whiteSpace: "pre-wrap"}}>{processItem.note}</em>
                            )}
                        </dd>
                    </dl>

                </Col>
                <Col lg={6}>
                    <ProcessItemSubstitute processItem={processItem}  />
                </Col>
            </Row>

            <div className="mt-3">
                <h3>Lista elementów</h3>

                {processItem.parts.length == 0 ? (
                    <Alert variant="warning">
                        Lista elementów nie została jeszcze dodana.&nbsp;
                        <Button variant="outline-primary" onClick={initProcessItemPartHandler}>
                            {initParts ? (
                                <><FontAwesomeIcon icon={faSpinner} spin={true}/> Wysyłam...</>
                            ) : (
                                <>Dodaj domyślną listę &raquo;</>
                            )}
                        </Button>
                    </Alert>
                ) : (
                    <Card className="mb-3">
                        <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                            <thead>
                            <tr>
                                <th style={{width: '60px'}}>Lp</th>
                                <th className="text-center" style={{width: '100px'}}>Czy dołączony</th>
                                <th style={{width: '250px'}}>Nazwa elementu</th>
                                <th>Opis</th>
                                <th style={{width: 0}}></th>
                            </tr>
                            </thead>
                            <ReactSortable
                                ref={sortRef}
                                list={processItemPartList}
                                // list={processItemPartList.map(x => ({ ...x, chosen: true }))}
                                setList={onProcessItemPartSetHandler}
                                tag={"tbody"}
                                handle=".item-handle-sort"
                                ghostClass="table-info"
                                animation={50}
                            >
                                {processItemPartList.map((processItemPart: ProcessItemPartType, index: number) => (
                                    <ProcessItemPartComponent
                                        index={index}
                                        key={processItemPart.id}
                                        processItemPart={processItemPart}
                                        processItem={processItem}
                                    />
                                ))}
                            </ReactSortable>
                        </Table>
                    </Card>
                )}

                <Button variant={"outline-primary"} className={"mb-3"}
                        onClick={e => setProcessItemPartCreateModal(true)}
                >
                    <FontAwesomeIcon icon={faPlusCircle} fixedWidth={true}/> Dodaj element
                </Button>

                <Modal show={showProcessItemPartCreateModal} onHide={() => setProcessItemPartCreateModal(false)}
                       size={"lg"} centered={true}
                       className="modal-blur"
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5"><FontAwesomeIcon icon={faListRadio} fixedWidth={true}/> Nowy element</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProcessItemPartCreate
                            process={process}
                            processItem={processItem}
                            afterSuccessSave={afterProcessItemPartSuccessSave}
                        />
                    </Modal.Body>
                </Modal>
            </div>

        </div>
    );

}

export default ProcessItemComponent;