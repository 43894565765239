import React from "react";
import {EstimateItemFormComponent} from "@estimate/components";
import {EstimateType} from "@estimate/types/estimate.types";
import {createEstimateItem} from "@estimate/utils/axios";
import {AxiosResponse} from "axios";
import {EstimateItemRequestType, EstimateItemType} from "@estimate/types/estimateItem.types";
import {Button, Modal} from "react-bootstrap";

interface EstimateCreateProps {
    estimate: EstimateType,
    afterSuccessSave: Function,
}

const estimateItemDefault: EstimateItemType = {
    quantity: 1
}

const EstimateItemCreateComponent: React.FC<EstimateCreateProps> = ({estimate, afterSuccessSave}) => {

    const [showEstimateItemForm, setShowEstimateItemForm] = React.useState<boolean>(false);

    const showEstimateItemFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowEstimateItemForm(prevState => !prevState);
    }

    const onAfterSuccessSaveHandler = (): void => {
        setShowEstimateItemForm(false);
        afterSuccessSave();
    }

    const saveEstimateItem = async (data: EstimateItemRequestType): Promise<AxiosResponse> => {
        return createEstimateItem(estimate.id, data);
    }

    return (
        <>
            <Button
                onClick={showEstimateItemFormHandler}
                variant={"outline-primary"}
            >+ dodaj własną pozycję</Button>

            <Modal show={showEstimateItemForm} onHide={() => setShowEstimateItemForm(false)}
                   size={"lg"} centered={true}
                   className="modal-blur"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Dodanie pozycji kosztorysu</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EstimateItemFormComponent
                        estimateItem={{...estimateItemDefault}}
                        saveEstimateItem={saveEstimateItem}
                        afterSuccessSave={onAfterSuccessSaveHandler}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EstimateItemCreateComponent;