import Swal, {SweetAlertOptions, SweetAlertResult} from "sweetalert2";

export const deleteEstimateItemSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy usunąć element?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, usuń!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const archiveEstimateSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy przenieść kosztorys do archiwum?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, usuń!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const deleteEstimateSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy usunąć kosztorys?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK, usuń!',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const acceptEstimateSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy potwierdzić wycenę?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}

export const rejectEstimateSwal = (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
    return Swal.fire({
        title: 'Czy odrzucić wycenę?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'TAK',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        ...options
    });
}
