import {NumberFormat, SortableColumn} from "@src/components";
import moment from "moment/moment";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {OfferFilterRequestType, OfferType} from "@offer/types/offer.types";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<OfferType>()

export const getOfferColumn = (offerFilter: OfferFilterRequestType) => {

    return [
        columnHelper.accessor(offer => offer.createdAt, {
            id: 'createdAt',
            header: () => <SortableColumn label="Data dodania" orderKey="createdAt" params={offerFilter}/>,
            cell: info => moment(info.getValue()).format('YYYY-MM-DD HH:mm')
        }),
        columnHelper.accessor(offer => offer.createdBy, {
            id: 'createdBy',
            header: () => <SortableColumn label="Dodał" orderKey="createdBy" params={offerFilter}/>,
            cell: info => {
                const createdBy = info.getValue();
                return `${createdBy.firstName} ${createdBy.lastName}`;
            }
        }),
        columnHelper.accessor(offer => offer, {
            id: 'number',
            header: () => <SortableColumn label="Numer" orderKey="number" params={offerFilter}/>,
            cell: info => {
                const offer = info.getValue();
                return <Link to={`/offer/${offer.id}`}>{offer.number}</Link>;
            }
        }),
        columnHelper.accessor(offer => offer, {
            id: 'title',
            header: () => <SortableColumn label="Tytuł" orderKey="title" params={offerFilter}/>,
            cell: info => {
                const offer = info.getValue();
                return <Link to={`/offer/${offer.id}`}>{offer.title}</Link>;
            }
        }),
        columnHelper.accessor('client', {
            id: 'client',
            header: () => <SortableColumn label="Klient" orderKey="client" params={offerFilter}/>,
            cell: info => {
                const client = info.getValue();
                return client.companyName
                    ? client.companyName
                    : `${client.firstName ?? ''} ${client.lastName ?? ''}`
            }
        }),
        columnHelper.accessor(offer => offer.status.name, {
            id: 'status',
            header: () => <SortableColumn label="Status" orderKey="status" params={offerFilter}/>,
            meta: { className: 'text-center' }
        }),
        columnHelper.accessor(offer => offer.termDate, {
            id: 'termDate',
            header: () => <SortableColumn label="Termin" orderKey="termDate" params={offerFilter}/>,
            meta: { className: 'text-center' }
        }),
        columnHelper.accessor(offer => offer.priceRegular, {
            id: 'priceRegular',
            header: () => <SortableColumn label="Wartość bazowa" orderKey="priceRegular" params={offerFilter}/>,
            cell: info => <NumberFormat value={info.getValue()}/>,
            meta: { className: 'text-end' }
        }),
        columnHelper.accessor(offer => offer.priceDiscount, {
            id: 'priceDiscount',
            header: () => <SortableColumn label="Rabat" orderKey="priceDiscount" params={offerFilter}/>,
            cell: info => <NumberFormat value={info.getValue()}/>,
            meta: { className: 'text-end' }
        }),
        columnHelper.accessor(offer => offer.percentDiscount, {
            id: 'percentDiscount',
            header: () => <SortableColumn label="Rabat%" orderKey="percentDiscount" params={offerFilter}/>,
            cell: info => <NumberFormat value={info.getValue()} extension="%"/>,
            meta: { className: 'text-end' }
        }),
        columnHelper.accessor(offer => offer.price, {
            id: 'price',
            header: () => <SortableColumn label="Wartość" orderKey="price" params={offerFilter}/>,
            cell: info => <NumberFormat value={info.getValue()}/>,
            meta: { className: 'text-end' }
        })
    ]
}