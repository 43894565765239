import React, {FormEventHandler, useEffect, useState} from "react";
import {
    ProcessStatusAssignType,
    ProcessType,
    ProcessStatusAssignErrorType
} from "@process/types/process.types";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {
    DictionaryDivisionEnum,
    DictionaryEnum,
    DictionaryFilterType,
    DictionaryType
} from "@dictionary/types/dictionary.types";
import {getDictionaryCollection} from "@dictionary/utils/axios";
import {DictionarySelect} from "@dictionary/components";
import {Maybe} from "@src/types/global.types";
import {UserSelect} from "@user/components";
import {UserType} from "@user/types/user.types";
import {assignProcessStatus} from "@process/utils/axios";
import {mapProcessStatusAssignTypeToRequest} from "@process/utils/mapper";
import {toast} from "react-toastify";
import {FormError} from "@src/components";
import {ProcessDictionaryEnum, ProcessSalonStatusKeyEnum} from "@process/types/process.enums";

interface ProcessShipmentProps {
    process: ProcessType,
    divisionKey: string,
    afterSave: Function,
}

const ProcessStatusFormComponent: React.FC<ProcessShipmentProps> = (
    {process, divisionKey, afterSave}
) => {

    const [divisionList, setDivisionList] = useState<DictionaryType[]>([]);
    const [statusAssign, setStatusAssign] = useState<Maybe<ProcessStatusAssignType>>()
    const [formErrors, setFormErrors] = useState<ProcessStatusAssignErrorType>({});
    const [isSending, setSending] = useState<boolean>(false);

    const fetchDivisionList = () => {
        const filter: DictionaryFilterType = {model: DictionaryEnum.Division}
        getDictionaryCollection(filter).then((response: DictionaryType[]) => setDivisionList(response))
        ;
    }

    const onUserChangeHandler = (user: Maybe<UserType>, name: string) => {
        setStatusAssign(prevState => ({...prevState, [name]: user}));
    }

    const onStatusChangeHandler = (status: Maybe<DictionaryType>, name: string) => {
        setStatusAssign(prevState => ({...prevState, [name]: status}));
    }

    const onDivisionChangeHandler = (division: DictionaryType) => {
        const user: Maybe<UserType> = division.key === DictionaryDivisionEnum.Salon
            ? process.salonUser : process.serviceUser;

        setStatusAssign(prevState => ({...prevState, division, user, status: null}));
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setStatusAssign(prevState => ({...prevState, [name]: value}));
    }

    useEffect(() => {
        fetchDivisionList();
    }, []);

    useEffect(() => {
        let newDivision: DictionaryType = process.division;
        if (divisionKey && divisionList.length > 0) {
            newDivision = divisionList.find(division => division.key === divisionKey);
        }

        const actualDivisionStatus: Maybe<DictionaryType> = {
            'salon': process.salonStatus,
            'service': process.serviceStatus,
        }[process.division.key] ?? null;

        setStatusAssign({
            division: newDivision,
            user: process.user,
            divisionStatus: actualDivisionStatus,
            invoiceNumber: process.invoiceNumber,
        })
    }, [process, divisionKey, divisionList]);


    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        assignProcessStatus(process.id, mapProcessStatusAssignTypeToRequest(statusAssign))
            .then(r => {
                toast.success("Zapisano.");
                afterSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => setSending(false))

    }

    if (!statusAssign) {
        return;
    }

    return (
        <Form onSubmit={onSubmitHandler}>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="required text-md-end">Status w Twoim dziale:</Form.Label>
                <Col sm={8}>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">{process.division.name}</InputGroup.Text>
                        <DictionarySelect
                            model={process.division.key === DictionaryDivisionEnum.Salon
                                ? ProcessDictionaryEnum.ProcessStatusSalon
                                : ProcessDictionaryEnum.ProcessStatusService
                            }
                            name="divisionStatus"
                            value={statusAssign.divisionStatus}
                            onChange={onStatusChangeHandler}
                            required={true}
                            isInvalid={!!formErrors.divisionStatus}
                        />
                        <FormError errors={formErrors.divisionStatus}/>
                    </InputGroup>
                </Col>
            </Form.Group>

            {(
                process.division.key === DictionaryDivisionEnum.Salon
                && statusAssign.divisionStatus.key === ProcessSalonStatusKeyEnum.Done
            ) && (
                <Form.Group className="mb-3" as={Row}>
                    <Form.Label column className="required text-md-end">Numer faktury lub paragonu</Form.Label>
                    <Col sm={8}>
                        <Form.Control type="text" name="invoiceNumber"
                                      value={statusAssign.invoiceNumber ?? ''}
                                      onChange={onChangeHandler}
                                      isInvalid={!!formErrors.invoiceNumber}
                        />
                        <FormError errors={formErrors?.invoiceNumber}/>
                    </Col>
                </Form.Group>
            )}

            <div className="mt-5">Wybierz do kogo chcesz przypisać urządzenie</div>
            <hr className="mt-2"/>

            <Form.Group className="mb-3" as={Row}>
                <Col className="required text-md-end mb-3">Przypisz do działu:</Col>
                <Col sm={9}>
                    {divisionList.map((division: DictionaryType) => (
                        <label className="form-check form-check-inline" key={division.id}>
                            <input className="form-check-input" type="radio" name="division_id" value={division.id}
                                   checked={division.id === statusAssign.division?.id}
                                   onChange={() => onDivisionChangeHandler(division)}
                            />
                            <span className="form-check-label">{division.name}</span>
                        </label>
                    ))}
                    <FormError errors={formErrors.division}/>
                    <small className="form-hint">Przypisanie do działu salonu lub serwisu oznacza, który dział będzie
                        odpowiedzialny za dalszą obsługę urządzenia.</small>
                </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="required text-md-end">Przypisz do:</Form.Label>
                <Col sm={9}>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">{statusAssign.division.name}</InputGroup.Text>
                        <UserSelect
                            value={statusAssign.user}
                            name="user"
                            onChange={onUserChangeHandler}
                            isInvalid={!!formErrors.user}
                        />
                        <FormError errors={formErrors.user}/>
                    </InputGroup>
                </Col>
            </Form.Group>

            {process.division.id !== statusAssign.division.id && (
                <Form.Group className="mb-3" as={Row}>
                    <Form.Label column className="required text-md-end">Status w dziale:</Form.Label>
                    <Col sm={9}>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1">{statusAssign.division.name}</InputGroup.Text>
                            <DictionarySelect
                                model={statusAssign.division.key === DictionaryDivisionEnum.Salon
                                    ? ProcessDictionaryEnum.ProcessStatusSalon
                                    : ProcessDictionaryEnum.ProcessStatusService
                                }
                                name="status"
                                value={statusAssign.status}
                                onChange={onStatusChangeHandler}
                                required={true}
                                isInvalid={!!formErrors.status}
                                useDefault={true}
                            />
                        </InputGroup>
                        <FormError errors={formErrors.status}/>
                    </Col>
                </Form.Group>
            )}

            <Row className="mb-4">
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>Notatka</Form.Label>
                        <Form.Control as="textarea" name="note" rows={3}
                                      onChange={onChangeHandler}
                                      value={statusAssign.note ?? ''}
                                      data-bs-toggle="autosize"
                                      isInvalid={!!formErrors.note}
                        />
                        <FormError errors={formErrors.note}/>
                    </Form.Group>
                </Col>
            </Row>

            <Button type="submit" variant="primary" disabled={isSending} className="mt-3">
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default ProcessStatusFormComponent;