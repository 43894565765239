import React from 'react';
import ReactDOM, {Root} from 'react-dom/client';
import './styles/index.scss';
import App from './views/App';

import 'bootstrap';

// import reportWebVitals from './reportWebVitals';
import {store} from "./store";
import {Provider} from "react-redux";
import {setupInterceptors} from "@src/library/axios.library";

const root: Root = ReactDOM.createRoot(document.querySelector('.page'));
root.render(
  <React.Fragment>
      <Provider store={store}>
        <App />
      </Provider>
  </React.Fragment>
);

setupInterceptors(store)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
