import React from "react";
import {ChangePassword} from "@auth/components";

interface ChangePasswordProps {
}

const ChangePasswordView: React.FC<ChangePasswordProps> = () => {

    return (
        <div className="container container-tight py-4 view-change-password">
            <div className="card card-md">
                <div className="card-body">
                    <h2 className="h2 text-center mb-4">Zmień hasło</h2>
                    <ChangePassword />
                </div>
            </div>
        </div>
    )
};

export default ChangePasswordView;