import axios from "axios";
import { API_URI } from "../config/common.config";
import {toast} from "react-toastify";
import {logout} from "@auth/store/authSlice";
import {setAppLoading} from "@layout/store/appSlice";

export const client = axios.create({
    baseURL: API_URI,
});

export const setupInterceptors = (store) => {
    const { dispatch } = store;

    client.interceptors.request.use(
        request => {
            dispatch(setAppLoading(true))
            const token = window.localStorage.getItem('auth.token');
            if (token) {
                request.headers['Authorization'] = `Bearer ${token}`
            }
            return request;
        }, (error) => {
            dispatch(setAppLoading(false))
            toast.error("Wystąpił błąd :/");
            console.log('Error Request: ', error);
            return Promise.reject(error);
        }
    );

    client.interceptors.response.use(
        (response) => {
            dispatch(setAppLoading(false))
            return response;
        },
        (error) => {
            dispatch(setAppLoading(false))
            const url = error?.response?.config?.url?.toString() ?? null;
            const responseStatus: number = error.response.status;

            if (url !== '/api/login' && responseStatus === 401) {
                toast.error('Zostałeś wylogowany')
                dispatch(logout());
            } else if (responseStatus > 401) {
                toast.error("Wystąpił błąd :/");
                console.log('Error Response URL: ', url);
                console.log('Status: ', responseStatus);
                console.log('Message: ', error.message);
                console.log('Data: ', error.response?.data ?? null);
            }

            return Promise.reject(error);
        }
    );
}
