import React, {useEffect, useState} from 'react';
import {OfferForm} from "../../components";
import {OfferInitRequest, OfferRequestType, OfferType} from "../../types/offer.types";
import {Link, useLocation} from "react-router-dom";
import {Maybe} from "@src/types/global.types";
import {createOffer, initOffer, updateOffer} from "@offer/utils/axios";
import {AxiosResponse} from "axios";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/pro-regular-svg-icons";

const OfferCreateView = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [offer, setOffer] = useState<Maybe<OfferType>>();

    const initOfferForm = () => {
        setLoading(true);
        const offerInitRequest: OfferInitRequest  = {
            client: searchParams.get('client') ?? null
        };
        initOffer(offerInitRequest)
            .then((offer: OfferType) => setOffer(offer))
            .finally(() => setLoading(false));
    }

    useEffect(() => {initOfferForm() }, []);

    const saveOffer = async (data: OfferRequestType): Promise<AxiosResponse> => {
        return createOffer(data);
    }

    if (!offer) {
        return null;
    }

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Oferty
                        </div>
                        <h2 className="page-title">
                            Nowa oferta
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <OfferForm offer={offer} saveOffer={saveOffer} />
            </div>
        </>
    );

}

export default OfferCreateView;