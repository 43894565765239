import React, {ReactElement, useMemo} from "react";
import Table from "react-bootstrap/Table";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import {ClientDropdownOptions} from "@client/components";
import {getClientColumn} from "./ClientTable.column";
import {ClientTableProps} from "./ClientTable.types";
import {Card} from "react-bootstrap";
import {Pagination} from "@src/components";

const ClientTable: React.FC<ClientTableProps> = (
    {clientCollection, clientFilter, pagination}
): ReactElement => {
    const tableColumns = useMemo(() => getClientColumn(clientFilter), [clientFilter]);
    const table = useReactTable({
        data: clientCollection,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <Card>
            <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} scope="col">
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </th>
                            ))}
                            <th className="text-center" style={{width: 0}}>
                                <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                        <td className="text-center">
                            <ClientDropdownOptions client={row.original}/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {pagination && (
                <Card.Footer>
                    <Pagination pagination={pagination}/>
                </Card.Footer>
            )}
        </Card>
    );
}

export default ClientTable;