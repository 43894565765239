import React from 'react';
import Swal from "sweetalert2";
import {client as clientAxios} from "@src/library/axios.library";
import {toast} from "react-toastify";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEllipsisVertical, faEye, faPen, faXmark} from '@fortawesome/pro-regular-svg-icons'
import {NumberFormat} from "@src/components";
import {OfferItemType} from "@offer/types/offer.types";
import {Col, Dropdown, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

interface OfferItemProps {
    offerItem: OfferItemType,
    index: number,
    refetch: Function,
    showOfferItemFormHandler: any,
}

const OfferItemComponent:React.FC<OfferItemProps> =
    ({offerItem, index, refetch, showOfferItemFormHandler}) =>
{
    const removeItemHandler = e =>{
        e.preventDefault();

        Swal.fire({
            title: 'Czy usunąć prozycję?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'TAK, usuń!',
            cancelButtonText: 'Anuluj',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try{
                    await clientAxios.delete(`/api/v1/offer/item/${offerItem.id}/delete`);
                } catch (error: any) {
                    if (axios.isAxiosError(error)) {
                        console.log(error.response)
                    }

                    toast.error("Błąd!");
                    return;
                }

                refetch();
                toast.success("Usunięto");
            }
        })
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <Row>
                    {offerItem.image && (
                        <Col sm={12} className="col-md-auto">
                            <a href={offerItem.location} target="_blank">
                                <img src={offerItem.image} style={{maxWidth: '80px', maxHeight: '80px'}}/>
                            </a>
                        </Col>
                    )}
                    <Col >
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                showOfferItemFormHandler(true, offerItem)
                            }}
                        >
                            <div>{offerItem.name}</div>
                            <div className="text-muted">{offerItem.code}</div>
                        </a>
                    </Col>
                </Row>
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnitRegular} />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnitDiscount} />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.percentDiscount} extension="%" />
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceUnit} />
            </td>
            <td className="text-end">
                {offerItem.quantity}
            </td>
            <td className="text-end">
                <NumberFormat value={offerItem.priceTotal} />
            </td>
            <td className="text-end">

                <Dropdown>
                    <Dropdown.Toggle variant="outline-cyan">
                        <FontAwesomeIcon icon={faEllipsisVertical}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => showOfferItemFormHandler(true, offerItem)}>
                            <FontAwesomeIcon icon={faPen} fixedWidth /> Edycja
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={removeItemHandler} className="text-red">
                            <FontAwesomeIcon icon={faXmark} fixedWidth/>&nbsp;Usuń
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
}

export default OfferItemComponent;