import React from "react";
import { ProcessType } from "@process/types/process.types";
import {Link} from "react-router-dom";
import {Maybe} from "@src/types/global.types";
import {useSelector} from "react-redux";
import {processState} from "@process/store/processSlice";

interface ProcessCustomerProps{
}

const ProcessCustomerComponent: React.FC<ProcessCustomerProps> = () => {
    const process: Maybe<ProcessType> = useSelector(processState);

    return (
        <>
            <dl className="dl-horizontal">
                <dd>
                    <Link
                        to={process?.customer?.client ? `/client/${process.customer.client.id}` : '#'}>
                        <h3 className="fs-2 fw-normal m-0">
                            {process.customer.firstName} {process.customer.lastName}
                        </h3>
                    </Link>
                </dd>
            </dl>
            {process.customer.companyName && (
                <dl className="dl-horizontal">
                    <dt>Firma:</dt>
                    <dd>{process.customer.companyName}</dd>
                </dl>
            )}
            {process.customer.taxNumber && (
                <dl className="dl-horizontal">
                    <dt>NIP:</dt>
                    <dd>{process.customer.taxNumber}</dd>
                </dl>
            )}

            <dl className="dl-horizontal">
                <dt>E-mail:</dt>
                <dd>
                    <a href={"mailto:" + process.customer.email}>
                        {process.customer.email}
                    </a>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Telefon:</dt>
                <dd>{process.customer.phone}</dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Adres:</dt>
                <dd>
                    <div>{process.customer.address.street}</div>
                    <div>{process.customer.address.postCode} {process.customer.address.city}</div>
                </dd>
            </dl>
        </>
    );
};

export default ProcessCustomerComponent;