import React, {ReactElement} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faEye, faPen, faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {ClientType} from "@client/types/client.types";
import {Maybe} from "@src/types/global.types";

interface ClientOptionsProps {
    client: ClientType,
    buttonSize?: Maybe<'sm' | 'lg'>
}

const ClientDropdownOptionsComponent: React.FC<ClientOptionsProps> = ({client, buttonSize}): ReactElement => {

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-cyan" size={buttonSize}>
                <FontAwesomeIcon icon={faEllipsisVertical}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/client/${client.id}`}>
                    <FontAwesomeIcon icon={faEye} fixedWidth={true}/> Karta</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/client/edit/${client.id}`}>
                    <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item as={Link} to={`/offer/create?client=${client.id}`}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth/> Dodaj ofertę
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ClientDropdownOptionsComponent;