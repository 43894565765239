import {Link} from "react-router-dom";
import React from "react";
import {ProcessType} from "@process/types/process.types";

interface ProcessEstimateFormCommonProps {
    process: ProcessType,
}

const ProcessEstimateFormCommonComponent: React.FC<ProcessEstimateFormCommonProps> = (
    {process}
) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt></dt>
                <dd>
                    <h3 className="m-0">{process.title}</h3>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Number zgłoszenia:</dt>
                <dd>
                    <Link to={`/process/${process.id}`}>
                        {process.number}
                    </Link>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Data przyjęcia:</dt>
                <dd>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>{process.date}</div>
                        <div className="fst-italic small text-muted">{process.createdAt}</div>
                    </div>
                </dd>
            </dl>
            <dl className="dl-horizontal border-0 mb-4">
                <dt>Przypisane do:</dt>
                <dd>{process.user.firstName} {process.user.lastName}</dd>
            </dl>
        </>
    );
}

export default ProcessEstimateFormCommonComponent;