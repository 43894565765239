import React from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {NumberFormat} from "@src/components";

interface EstimateSummaryProps{
    estimate: EstimateType
}

const EstimateSummaryComponent: React.FC<EstimateSummaryProps> = ({estimate}) => {

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Status:</dt>
                <dd>{estimate.status.name}</dd>
            </dl>

            <dl className="dl-horizontal border-0">
                <dt>Wycena:</dt>
                <dd className="d-md-flex justify-content-between">
                    <div>
                        <NumberFormat value={estimate.priceGross}/>
                        &nbsp;<small className="text-muted">brutto</small>
                    </div>
                    <div>
                        <NumberFormat value={estimate.priceNet}/>
                        &nbsp;<small className="text-muted">netto</small>
                    </div>
                </dd>
            </dl>
        </>
    )
}

export default EstimateSummaryComponent;