import React, {useEffect, useState} from 'react';
import {ClientDropdownOptions, ClientFormComponent} from "@client/components";
import {Link, useParams} from "react-router-dom";
import {ClientRequestType, ClientType} from "@client/types/client.types";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-regular-svg-icons";
import {Maybe} from "@src/types/global.types";
import {getClient, updateClient} from "@client/utils/axios";
import {AxiosResponse} from "axios";

interface ClientEditProps{}

const ClientEditView:React.FC<ClientEditProps> = () => {
    const {id} = useParams();
    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        getClient(id).then((client:ClientType) => setClient(client));
    }, []);

    const saveClient = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return updateClient(client.id, data);
    }

    if (!client) {
        return null;
    }

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Klienci / Edycja klienta
                        </div>
                        <h2 className="page-title">
                            {client.firstName} {client.lastName} {client.companyName}
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <Col xs="auto">
                                <Link to={`/offer/create?client=${client.id}`} className="btn btn-outline-primary">
                                    <FontAwesomeIcon icon={faEye}
                                    /> <span className="d-none d-sm-inline">
                                        Karta klienta
                                    </span>
                                </Link>
                            </Col>
                            <Col xs="auto">
                                <ClientDropdownOptions client={client} />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <ClientFormComponent client={client} saveClient={saveClient}/>
            </div>
        </>
    )
}

export default ClientEditView;
