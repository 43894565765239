import React from "react";

import { Outlet} from "react-router-dom";
import {NavBar} from "@layout/components/NavBar";

const AppLayout: React.FC = (props) => {

    return (
        <>
            <NavBar />
            <div className="page-wrapper">
                <div className="container-xl">
                    <Outlet/>
                </div>
            </div>
        </>
    );
}

export default AppLayout;