import React, {useEffect, useState} from 'react';
import {ClientFormComponent} from "@client/components";
import {ClientRequestType, ClientType} from "@client/types/client.types";
import {AxiosResponse} from "axios";
import {createClient, initClient} from "@client/utils/axios";
import {Maybe} from "@src/types/global.types";
import {Col, Row} from "react-bootstrap";

const ClientCreateView = () => {
    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        initClient().then((client:ClientType) => setClient(client));
    }, []);


    const saveClient = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return createClient(data);
    }
    if (!client) {
        return null;
    }

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Klienci
                        </div>
                        <h2 className="page-title">
                            Nowy klient
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <ClientFormComponent client={client} saveClient={saveClient}/>
            </div>
        </>
    );

}

export default ClientCreateView;