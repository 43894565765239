import React, {ReactElement, useEffect, useState} from "react";
import {Maybe} from "@src/types/global.types";
import {ProcessType} from "@process/types/process.types";
import {useSelector} from "react-redux";
import {processState} from "@process/store/processSlice";
import {ProcessFlowType} from "@process/types/processFlow.types";
import {getProcessFlowList} from "@process/utils/axios/processFlow";
import ProcessFlowItem from "@process/components/ProcessFlow/ProcessFlowItem";


interface ProcessFlowProps {
}

const ProcessFlow: React.FC<ProcessFlowProps> = (): ReactElement => {
    const process: Maybe<ProcessType> = useSelector(processState);
    const [procesFlowList, setProcesFlowList] = useState<ProcessFlowType[]>([]);

    const fetchProcessFlowList = () => {
        if (!process){ return; }

        getProcessFlowList(process.id)
            .then((result: ProcessFlowType[]) => setProcesFlowList(result))

    }


    useEffect(() => fetchProcessFlowList(), [process]);

    if (!process) {
        return;
    }

    return (
        <div className="timeline">
            <div className="timeline-list">
                {procesFlowList.map((processFlow: ProcessFlowType) =>
                    <ProcessFlowItem processFlow={processFlow}/>
                )}
            </div>
        </div>
    );
}

export default ProcessFlow;