import React, {useEffect} from "react";
import {ProcessFileType, ProcessType} from "@process/types/process.types";
import {fetchProcessFiles} from "@process/utils/axios";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";
import ProcessImageComponent from "@process/components/ProcessImages/ProcessImage.component";

import styles from './ProductImages.module.scss';
import {Maybe} from "@src/types/global.types";
import {useSelector} from "react-redux";
import {processState} from "@process/store/processSlice";

interface ProcessFilesProps {
}

const ProcessImagesComponent: React.FC<ProcessFilesProps> = () => {
    const [processFiles, setProcessFiles] = React.useState<ProcessFileType[]>([]);
    const process: Maybe<ProcessType> = useSelector(processState);

    const fetchList = () => {
        fetchProcessFiles(process.id, {
            categoryKey: 'image'
        })
            .then((response: AxiosResponse<ProcessFileType[]>) => {
                setProcessFiles(response.data)
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div className={styles.processImages}>
            {processFiles.map((processFile: ProcessFileType) => (
                <ProcessImageComponent processFile={processFile} key={processFile.id}/>
            ))}
        </div>
    );
};

export default ProcessImagesComponent;