import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {ProcessType, ProcessFormType, ProcessInitRequest} from "@process//types/process.types";
import {createProcess, initProcess} from "@process/utils/axios";
import {ProcessForm} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {AxiosResponse} from "axios";

interface ProcessCreateProps {
}

const ProcessCreateView: React.FC<ProcessCreateProps> = () => {
    const [process, setProcess] = React.useState<Maybe<ProcessType>>();

    useEffect(() => {
        const initRequest: ProcessInitRequest = {};
        initProcess(initRequest).then((process: ProcessType) => {
            setProcess(process);
        });
    }, []);

    const saveProcess = async (data: ProcessFormType): Promise<AxiosResponse> => {
        return createProcess(data);
    }

    if (!process) {
        return null
    }

    return (
        <div className="view-process-create">
            <div className="page-header mb-5">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Serwis
                        </div>
                        <h2 className="page-title">
                            Nowy proces serwisowy
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <ProcessForm process={process} saveProcess={saveProcess}/>
            </div>
        </div>
    )
};

export default ProcessCreateView;