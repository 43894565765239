import React from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {Maybe} from "@src/types/global.types";
import {Link} from "react-router-dom";
import {EstimateStatusKeyEnum} from "@estimate/types/estimate.enums";

interface ProcessSummaryProps{
    estimate: Maybe<EstimateType>
}

const ProcessSummaryEstimateAcceptComponent: React.FC<ProcessSummaryProps> = ({estimate}) => {

    if (!estimate) {
        return (
            <span className="badge">- - -</span>
        );
    }

    if (estimate?.status?.key === EstimateStatusKeyEnum.Accepted) {
        return (
            <div className="d-flex gap-3 justify-content-between">
                <span className="badge bg-green-lt">TAK</span>
                <span className="small text-muted fst-italic">Data akceptacji: {estimate.acceptedAt}</span>
            </div>
        );
    }

    if (estimate?.status?.key === EstimateStatusKeyEnum.Rejected) {
        return (
            <div className="d-flex gap-3 justify-content-between">
                <span className="badge bg-red-lt">Nie</span>
                <span className="small text-muted fst-italic">Data odrzucenia: {estimate.rejectedAt}</span>
            </div>
        );
    }

    if (estimate?.status?.key === EstimateStatusKeyEnum.Sent) {
        return (
            <div className="d-flex gap-3">
                <span className="badge bg-blue-lt">Wysłano do akeptacji</span>
                <span>Data wysłania: @todo</span>
            </div>
        );
    }

    if (estimate?.status?.key === EstimateStatusKeyEnum.Draft) {
        return (
            <div className="d-flex gap-3 justify-content-between">
                <span className="badge">- - -</span>
                <Link to="#" className="text-primary" onClick={() => alert('Ta funkcja nie jest jeszcze przygotowana')}>
                    wyślij &raquo;
                </Link>
            </div>
        );
    }
};

export default ProcessSummaryEstimateAcceptComponent;