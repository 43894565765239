import React from "react";
import {RecoverPassword} from "@auth/components";
import {Link} from "react-router-dom";

const RecoverPasswordView:React.FC = () => {

    return (
        <div className="container container-tight py-4 view-recover-password">
            <div className="card card-md">
                <div className="card-body">
                    <h2 className="h2 text-center mb-4">Odzyskanie hasła</h2>
                    <div className="mb-5">
                        <RecoverPassword/>
                    </div>
                    <Link to="/">
                        &laquo; wróć do logowania
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RecoverPasswordView;