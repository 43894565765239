import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {login} from "@auth/store/authSlice";
import {AppDispatch} from "@src/store";
import {client} from "@src/library/axios.library";
import {Button, Form} from "react-bootstrap";
import {FormError} from "@src/components";

export interface LoginForm {
    email: string,
    password: string,
}

const defaultLoginForm: LoginForm = {
    email: '',
    password: '',
};

const LoginComponent: React.FC = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<LoginForm>(defaultLoginForm);
    const [loginError, setLoginError] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false)

    const onChangeHandler = e => {
        const name = e.target.name;
        const type = e.target.type;
        let value = null;

        if(type === 'checkbox'){
            value = e.target.checked;
        }else{
            value = e.target.value;
        }

        setLoginError(false);
        setFormData(prevState => {
            return {...prevState, ...{[name]: value}}
        });
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        setIsSending(true);
        client.post('/api/login', formData)
            .then(response => {
                dispatch(login({'token': response.data.token}));
            })
            .catch(error => {
                setLoginError(true);
            }).finally(() => {
                setIsSending(false);
            })
        ;
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    required={true}
                    value={formData.email}
                    onChange={onChangeHandler}
                    isInvalid={!!loginError}
                />
                {loginError && (
                    <div className="invalid-feedback">Błędny login lub hasło</div>
                )}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    required={true}
                    value={formData.password}
                    onChange={onChangeHandler}
                    isInvalid={!!loginError}
                />
            </Form.Group>

            <div className="form-footer">
                <Button type="submit" className="btn btn-primary w-100">
                    {isSending ? 'Loguję...' : 'Zaloguj'}
                </Button>
            </div>
        </Form>
    );
}

export default LoginComponent;