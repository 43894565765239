import {
    ProcessFileFormType, ProcessFileType, ProcessFilterRequestType, ProcessFilterType,
    ProcessFormType, ProcessInvoiceNumberRequestType,
    ProcessItemFormType, ProcessItemPartRequestType, ProcessItemPartType,
    ProcessItemType, ProcessStatusAssignRequestType, ProcessStatusAssignType,
    ProcessType
} from "@process/types/process.types";
import {DictionaryDivisionEnum} from "@dictionary/types/dictionary.types";
import {ProcessSalonStatusKeyEnum} from "@process/types/process.enums";

export const mapProcessToProcessFormType = (process: ProcessType): ProcessFormType => {

    let processItems: ProcessItemFormType[] = [];
    process.items.map((processItem: ProcessItemType) => {
        processItems.push({
            id: processItem.id,
            name: processItem.name,
            serialNumber: processItem.serialNumber,
            note: processItem.note,
        });
    })

    return {
        department: process?.department?.id ?? '',
        number: process.number ?? '',
        // user: process?.user?.id ?? '',
        // status: process?.salonStatus?.id ?? '',
        title: process.title ?? '',
        date: process.date ?? '',
        note: process.note ?? '',
        customer: {
            client: process?.customer?.client?.id ?? '',
            firstName: process?.customer?.firstName ?? '',
            lastName: process?.customer?.lastName ?? '',
            companyName: process?.customer?.companyName ?? '',
            email: process?.customer?.email ?? '',
            phone: process?.customer?.phone ?? '',
            taxNumber: process?.customer?.taxNumber ?? '',
            address: {
                street: process?.customer?.address?.street ?? '',
                postCode: process?.customer?.address?.postCode ?? '',
                city: process?.customer?.address?.city ?? '',
            }
        },
        shipment: {
            shipmentMethod: process?.shipment?.shipmentMethod?.id ?? ''
        },
        incomeShipment: {
            shipmentMethod: process?.incomeShipment?.shipmentMethod?.id ?? ''
        },
        items: processItems
    };
}

export const mapProcessFileToProcessFileFormType = (processFile: ProcessFileType): ProcessFileFormType => {
    return {
        name: processFile.file.name ?? '',
        category: processFile.category?.id ?? ''
    }
}

export const mapProcessFilterRequestToProcessFilterType =
    (processFilterRequestType: ProcessFilterRequestType)
        : ProcessFilterType => {
        return {
            search: processFilterRequestType?.search ?? '',
            page: processFilterRequestType.page ?? 1,
            order: [],
            salonStatus: {id: processFilterRequestType?.salonStatus ?? null},
            serviceStatus: {id: processFilterRequestType?.serviceStatus ?? null},
            createdBy: {id: processFilterRequestType?.createdBy ?? null},
            user: {id: processFilterRequestType?.user ?? null},
            client: {id: processFilterRequestType?.client ?? null},
            department: {id: processFilterRequestType?.department ?? null},
        }
    }

export const mapProcessItemPartToRequestType = (processItemPart: ProcessItemPartType): ProcessItemPartRequestType => {
    return {
        name: processItemPart?.name ?? null,
        isAttach: processItemPart?.isAttach ?? null,
        note: processItemPart?.note ?? null,
    }
}

export const mapProcessStatusAssignTypeToRequest = (processStatusAssign: ProcessStatusAssignType): ProcessStatusAssignRequestType => {
    let request: ProcessStatusAssignRequestType = {
        divisionStatus: processStatusAssign.divisionStatus?.id ?? null,
        invoiceNumber: null,
        division: processStatusAssign.division?.id ?? null,
        status: processStatusAssign.status?.id ?? null,
        user: processStatusAssign.user?.id ?? null,
        note: processStatusAssign.note ?? null,
    }

    if (
        processStatusAssign.division.key === DictionaryDivisionEnum.Salon
        && processStatusAssign.divisionStatus.key === ProcessSalonStatusKeyEnum.Done
    ) {
        request.invoiceNumber = processStatusAssign.invoiceNumber ?? null
    }

    return request;
}

export const mapProcessTypeToProcessInvoiceNumberRequestType = (process: ProcessType): ProcessInvoiceNumberRequestType => {
    return {
        invoiceNumber: process.invoiceNumber ?? null,
    }
}
