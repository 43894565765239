import React from "react";
import {faGearComplex} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isAppLoading} from "@layout/store/appSlice";
import {useSelector} from "react-redux";

const LoaderComponent: React.FC = () => {
    const isLoading: boolean = useSelector(isAppLoading);

    if (!isLoading) {
        return null;
    }

    return (
        <div className="app-layout-loader"><FontAwesomeIcon icon={faGearComplex} spin={true}/> Wczytuje dane...</div>
    );
};

export default LoaderComponent;