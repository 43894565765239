import React, {ReactElement} from 'react';
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faEye, faPen} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";
import {Maybe} from "@src/types/global.types";
import {ProcessType} from "@process/types/process.types";
import {ButtonVariant} from "react-bootstrap/types";

interface ProcessDropdownOptionsProps {
    process: ProcessType
    buttonSize?: Maybe<'sm' | 'lg'>
    buttonVariant?: Maybe<ButtonVariant>
}

const ProcessDropdownOptionsComponent:React.FC<ProcessDropdownOptionsProps> = (
    {process, buttonSize, buttonVariant}
): ReactElement => {

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonVariant} size={buttonSize}>
                <FontAwesomeIcon icon={faEllipsisVertical}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/process/${process.id}`}>
                    <FontAwesomeIcon icon={faEye} fixedWidth={true} />&nbsp;Karta</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/process/edit/${process.id}`}>
                    <FontAwesomeIcon icon={faPen} fixedWidth/>&nbsp;Edycja
                </Dropdown.Item>
                {/*<Dropdown.Divider />*/}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ProcessDropdownOptionsComponent;