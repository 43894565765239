import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Maybe, PaginationDataType} from "@src/types/global.types";
import {UserFilterRequestType, UserType} from "@user/types/user.types";
import {getUser, getUserCollection} from "@user/utils/axios";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

interface UserSelectProps {
    onChange: any,
    value?: Maybe<UserType>,
    name?: string,
    className?: Maybe<string>,
    isClearable?: Maybe<boolean>,
    placeholder?: Maybe<string>,
    isInvalid?: Maybe<boolean>,
}


const UserSelectComponent: React.FC<UserSelectProps> = (
    {onChange, value: initValue, name, className, isClearable, placeholder, isInvalid}
) => {
    const [selectOptions, setSelectOptions] = useState<UserType[]>([]);
    const [userCollection, setUserCollection] = useState<UserType[]>([]);
    const [selectedUser, setSelectedUser] = useState<Maybe<UserType>>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const fetchUserCollection = () => {
        const filter: UserFilterRequestType = {search: searchValue}
        setLoading(true);
        getUserCollection(filter)
            .then((response: PaginationDataType<UserType>) => setUserCollection(response.items))
            .finally(() => setLoading(false))
        ;
    }

    const fetchSelectedUser = () => {
        if (!(initValue?.id ?? null)) {
            setSelectedUser(null);
            return;
        }

        if (initValue && selectedUser && initValue.id === selectedUser.id) {
            const selectedUserOK = Object.keys(selectedUser).length > 1;
            if (selectedUserOK) {
                return;
            }
        }

        const initUserOK =  Object.keys(initValue).length > 1;
        if (initUserOK) {
            setSelectedUser(initValue);
            return;
        }

        const findUser: UserType = userCollection.find((user: UserType) => user.id === initValue.id) ?? null;
        if (findUser) {
            setSelectedUser(findUser);
            return;
        }

        setLoading(true);
        getUser(initValue.id)
            .then((responseUser: UserType) => setSelectedUser(responseUser))
            .finally(() => setLoading(false))
    }


    const onChangeHandler = (user: OnChangeValue<UserType, false>) => {
        setSelectedUser(user);
        onChange(user, name);
    }

    const prepareSelectOptions = () => {
        const options: UserType[] = [];
        if (selectedUser?.id) {
            const selectedIndex: number = userCollection.findIndex((user: UserType) => user.id === selectedUser.id);
            if (0 > selectedIndex) {
                options.push(selectedUser);
            }
        }
        options.push(...userCollection);
        setSelectOptions(options);
    }

    useEffect(() => {
        const timer = setTimeout(fetchUserCollection, 250);
        return () => clearTimeout(timer);
    }, [searchValue]);

    useEffect(prepareSelectOptions, [userCollection, selectedUser]);
    useEffect(fetchSelectedUser, [initValue]);

    if (isInvalid) {
        className = ` is-invalid ${className}`
    }

    return (
        <Select
            options={selectOptions}
            name={name}
            value={selectedUser}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}
            getOptionLabel={(user: UserType) => `${user.firstName} ${user.lastName}`}
            getOptionValue={(user: UserType) => user.id}
            onChange={onChangeHandler}
            isLoading={isLoading}
            inputValue={searchValue}
            onInputChange={value => setSearchValue(value)}
            placeholder={placeholder ?? "Wybierz użytkownika"}
            noOptionsMessage={() => "Nie znaleziono użytkownika"}
            isClearable={isClearable}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '0 8px'}),
                clearIndicator: (baseStyles) => ({...baseStyles, padding: '6px 8px'}),
            }}
        />
    );
}

export default UserSelectComponent;
