import React, {useState} from "react";
import {useNavigate} from "react-router-dom"
import {OfferType} from "@offer/types/offer.types";
import {toast} from "react-toastify";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormError} from "@src/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {DictionarySelect} from "@dictionary/components";
import {DictionaryType} from "@dictionary/types/dictionary.types";
import {Maybe} from "@src/types/global.types";
import {ClientSelect} from "@client/components";
import {ClientType} from "@client/types/client.types";
import {mapOfferToOfferRequestType} from "@offer/utils/mapper";

interface OfferFormProps {
    offer: OfferType,
    saveOffer: Function,
}

const OfferFormComponent:React.FC<OfferFormProps> = ({offer: initOffer, saveOffer}) => {
    const navigate = useNavigate();
    const [isSending, setSending] = useState<boolean>(false)
    const [formErrors, setFormErrors] = useState<any>({});
    const [offer, setOffer] = useState<OfferType>(initOffer);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setOffer(prevState => ({...prevState, ...{[name]: value}}));
        removeFromFormErrors(name);
    }

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onStatusChangeHandler = (status?: Maybe<DictionaryType>) => {
        removeFromFormErrors('status');
        setOffer(prevState => ({...prevState, status: status}));
    }

    const onClientChangeHandler = (client?: Maybe<ClientType>) => {
        removeFromFormErrors('client');
        setOffer((prevState: OfferType) => {
            return {...prevState, client}
        });
    }

    const onSubmitHandler = async e =>  {
        e.preventDefault();
        setSending(true);

        saveOffer(mapOfferToOfferRequestType(offer))
            .then((response) => {
                toast.success("Oferta została zapisana");
                navigate(`/offer/${response.data?.id ?? offer.id}`);
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => {
            setSending(false);
        })
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label className="required">Tytuł oferty</Form.Label>
                <Form.Control type="text"
                       name="title"
                       value={offer?.title ?? ''}
                       onChange={onChangeHandler}
                      isInvalid={!!formErrors.title}
                />
                <FormError errors={formErrors.title} />
            </Form.Group>

            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="form-label required">Numer oferty</Form.Label>
                        <Form.Control
                            type="text" name="number"
                            value={offer?.number ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.number}
                        />
                        <FormError errors={formErrors.number} />
                    </Form.Group>
                </Col>
                <Col md={6} >
                    <Form.Group className="mb-3">
                        <Form.Label className="form-label required">Klient</Form.Label>
                        <ClientSelect
                            onChange={onClientChangeHandler}
                            value={offer.client}
                            placeholder="Wybierz klienta"
                            isClearable={true}
                            className={formErrors.client ? ' is-invalid' : ''}
                        />
                        <FormError errors={formErrors.client} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="form-label required">Status:</Form.Label>
                        <DictionarySelect
                            model="OfferStatus"
                            name="status"
                            value={offer.status}
                            onChange={onStatusChangeHandler}
                            className={formErrors.name ? ' is-invalid' : ''}
                            required={true}
                        />
                        <FormError errors={formErrors.status} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label className="form-label">Data ważności oferty</Form.Label>
                        <Form.Control type="date" name="termDate"
                            value={offer?.termDate ?? ''}
                            onChange={onChangeHandler}
                            isInvalid={!!formErrors.termDate}
                        />
                        <FormError errors={formErrors.termDate} />
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group className="mb-3">
                <Form.Label className="form-label">Opis oferty</Form.Label>
                <Form.Control as="textarea"
                    rows={3}
                    name="description"
                    value={offer?.description ?? ''}
                    onChange={onChangeHandler}
                    isInvalid={!!formErrors.description}
                />
            </Form.Group>

            <div className="text-center">
                <Button type="submit" className="btn btn-primary" disabled={isSending}>
                    {isSending ? (
                        <><FontAwesomeIcon icon={faSpinnerThird} spin={true} /> wysyłam</>
                    ) : 'Zapisz'}
                </Button>
            </div>
        </Form>
    )
}

export default OfferFormComponent;


