import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faScrewdriverWrench} from "@fortawesome/pro-light-svg-icons";
import Table from "react-bootstrap/Table";
import {UserType} from "@user/types/user.types";
import {auth, AuthState} from "@src/modules/auth/store/authSlice";
import {useSelector} from "react-redux";
import {AuthRoles} from "@src/modules/auth/utils/AuthRoles";
import {Button, Modal} from "react-bootstrap";
import {faKey, faXmark} from "@fortawesome/pro-light-svg-icons";
import ReactModal from "react-modal";
import {UserChangePasswordForm} from "@user/components";
import {ProcessEstimateFormCommon} from "@process/components";
import {EstimateCreateComponent} from "@estimate/components";

interface UserCardProps {
    user: UserType
}

const UserCardComponent: React.FC<UserCardProps> = ({user}) => {
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const [showChangePasswordModal, setChangePasswordModal] = useState<boolean>(false);

    return (
        <>
            <Table bordered>
                <tbody>
                <tr>
                    <td scope="col-1" className={"text-end"}></td>
                    <td scope="col-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="m-0">{user.firstName + " " + user.lastName}</h4>
                            {hasAdminRole && (
                                <Link to={"/user/edit/" + user.id}>
                                    <FontAwesomeIcon icon={faPencil}/> edytuj
                                </Link>
                            )}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="text-end">Telefon:</td>
                    <td>{user.phone}</td>
                </tr>
                <tr>
                    <td className="text-end">E-mail:</td>
                    <td>{user.email}</td>
                </tr>
                <tr>
                    <td className="text-end">Odział:</td>
                    <td>{user?.department?.name ?? ''}</td>
                </tr>
                <tr>
                    <td className="text-end">Stanowisko:</td>
                    <td>{user.jobPosition}</td>
                </tr>
                <tr>
                    <td className="text-end">Odział:</td>
                    <td>
                        {user.department && <>{user.department.code} {user.department.name}</>}
                    </td>
                </tr>
                </tbody>
            </Table>

            {hasAdminRole && (
                <>
                    <Button variant={"outline-primary"}
                            onClick={e => setChangePasswordModal(true)}
                    >
                        <FontAwesomeIcon icon={faKey} fixedWidth={true}/> Zmień hasło
                    </Button>

                    <Modal show={showChangePasswordModal} onHide={() => setChangePasswordModal(false)}
                           size={"lg"} centered={true}
                           className="modal-blur"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title as="h5">
                                <FontAwesomeIcon icon={faKey} fixedWidth={true}/> Zmiana hasła użytkownika
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <UserChangePasswordForm
                                user={user}
                                afterSuccessSave={() => setChangePasswordModal(false)}
                            />
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    )
}

export default UserCardComponent;