import React from "react";
import { ProcessType } from "@process/types/process.types";
import {Link} from "react-router-dom";
import {Card, Table} from "react-bootstrap";
import {sendCreatedEmailSwal} from "@process/utils/swal";
import {SweetAlertResult} from "sweetalert2";
import {sendCreatedEmail} from "@process/utils/axios";
import {toast} from "react-toastify";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessShipmentProps{
}

const ProcessDetailsComponent: React.FC<ProcessShipmentProps> = () => {
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const onEmailSendCreatedHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        sendCreatedEmailSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                sendCreatedEmail(process.id).then(() => {
                    toast('Wiadomość zostła wysłana.')
                    dispatch(refreshProcess());
                })
            }
        })
    }

    return (
        <>
            <h4 className="m-3 text-center">Data wysłania powiadomień e-mail:</h4>

            <Card>
                <Table hover className="table-vcenter card-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th className="text-center">Czy wysłano</th>
                        <th className="text-center">Data wysłania</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Rejestracja w serwisie:</td>
                        <td className="text-center">
                            {process.emailCreateSentAt ? (
                                <span className="badge bg-green-lt">Tak</span>
                            ) : (
                                <span className="badge bg-red-lt">Nie</span>
                            ) }
                        </td>
                        <td className="text-center">
                            {process.emailCreateSentAt}
                        </td>
                        <td className="text-end">
                            <Link to="#" className="text-primary" onClick={onEmailSendCreatedHandler}>
                                wyślij &raquo;
                            </Link>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Card>
        </>
    );
};

export default ProcessDetailsComponent;