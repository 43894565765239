import {
    ProcessInitRequest,
} from "@process/types/process.types";
import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {ProcessItemSubstituteRequestType, ProcessItemSubstituteType} from "@process/types/processItem.types";


export const initProcessItemSubstitute = async (processId: string, processItemId: string): Promise<ProcessItemSubstituteType> => {
    const {data} = await clientAxios.post<ProcessInitRequest, AxiosResponse<ProcessItemSubstituteType>>(`/api/v1/process/${processId}/item/${processItemId}/substitute/init`);
    return data;
}

export const createProcessItemSubstitute = async (processId: string, processItemId: string, part: ProcessItemSubstituteRequestType): Promise<AxiosResponse> => {
    return await clientAxios.post<ProcessItemSubstituteRequestType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/substitute/create`, part);
}

export const updateProcessItemSubstitute = async (processId: string, processItemId: string, part: ProcessItemSubstituteRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<ProcessItemSubstituteRequestType, AxiosResponse>(`/api/v1/process/${processId}/item/${processItemId}/substitute/update`, part);
}