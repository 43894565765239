import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {AxiosResponse} from "axios";
import {ProcessItemPartErrorType, ProcessItemPartType} from "@process/types/process.types";
import {FormError} from "@src/components";
import {mapProcessItemPartToRequestType} from "@process/utils/mapper";

interface ProcessItemPartFormProps {
    processItemPart: ProcessItemPartType,
    saveProcessItemPart: Function,
    afterSuccessSave: Function,
}

const ProcessItemPartFormComponent: React.FC<ProcessItemPartFormProps> = (
    {processItemPart: initProcessItemPart, saveProcessItemPart, afterSuccessSave}
) => {
    const [processItemPart, setProcessItemPart] = useState<ProcessItemPartType>(initProcessItemPart);
    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<ProcessItemPartErrorType>({});

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name: string = e.target.name;
        const type: string = e.target.type;
        let value: string|boolean;

        if ('checkbox' === type) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setProcessItemPart(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        saveProcessItemPart(mapProcessItemPartToRequestType(processItemPart))
            .then((response: AxiosResponse) => {
                afterSuccessSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }

    useEffect(() => {
        setProcessItemPart(initProcessItemPart)
    }, [initProcessItemPart]);


    return (
        <Form onSubmit={onSubmitHandler}>

            <Form.Group className="mb-3">
                <Form.Label className="required">Nazwa elementu:</Form.Label>
                <Form.Control
                    value={processItemPart.name ?? ''}
                    onChange={onChangeHandler}
                    name="name"
                    isInvalid={!!formErrors.name}
                />
                <FormError errors={formErrors.name} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Czy dołączony?:</Form.Label>
                <Form.Check
                    type="switch"
                    name="isAttach"
                    checked={processItemPart.isAttach}
                    onChange={onChangeHandler}
                />
                <FormError errors={formErrors.isAttach} />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Opis:</Form.Label>
                <Form.Control
                    value={processItemPart.note ?? ''}
                    onChange={onChangeHandler}
                    name="note"
                    isInvalid={!!formErrors.note}
                />
                <FormError errors={formErrors.note} />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default ProcessItemPartFormComponent;