import React, {useEffect} from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {Maybe} from "@src/types/global.types";
import {getEstimate} from "@estimate/utils/axios";
import {useParams} from "react-router-dom";
import {EstimateCardComponent} from "@estimate/components";
import {Col, Row} from "react-bootstrap";

interface EstimateCardProps{

}

const EstimateCardView: React.FC<EstimateCardProps> = () => {
    const [estimate, setEstimate] = React.useState<Maybe<EstimateType>>();
    const {id: estimateId} = useParams();

    const fetchEstimate = (): void => {
        getEstimate(estimateId).then((response: EstimateType) => {
            setEstimate(response);
        })
    }

    useEffect(() => {
        fetchEstimate();
    }, []);

    if (!estimate) {
        return null;
    }

    return (
        <div className="view-estimate-card">
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Kosztorys
                        </div>
                        <h2 className="page-title">
                            {estimate.name}
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <EstimateCardComponent estimate={estimate} fetchEstimate={fetchEstimate} />
            </div>
        </div>
    )
}

export default EstimateCardView;