import React, {useState} from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPen, faScrewdriverWrench, faBoxArchive, faStar} from "@fortawesome/pro-light-svg-icons";
import {NumberFormat} from "@src/components";
import {toDateTimeFormat} from "@src/helpers/utils.helper";
import {Link} from "react-router-dom";
import { Dropdown, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import {ProcessEstimateFormCommon} from "@process/components";
import {ProcessType} from "@process/types/process.types";
import {EstimateEditComponent} from "@estimate/components";
import {archiveEstimateSwal} from "@estimate/utils/swal";
import {SweetAlertResult} from "sweetalert2";
import {archiveEstimate} from "@estimate/utils/axios";
import {toast} from "react-toastify";
import {assignProcessEstimateSwal} from "@process/utils/swal";
import {assignProcessEstimate} from "@process/utils/axios";

interface ProcessEstimationListItemProps{
    estimate: EstimateType
    process: ProcessType
    fetchList: Function
}

export const ProcessEstimationListItemComponent: React.FC<ProcessEstimationListItemProps> = (
    {estimate, process, fetchList}
) => {

    const [showEstimateEditModal, setEstimateEditModal] = useState<boolean>(false);

    const afterSuccessSave = () => {
        setEstimateEditModal(false);
        fetchList();
    }

    const archiveEstimateHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        archiveEstimateSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                archiveEstimate(estimate.id).then(() => {
                    toast.success("Kosztorys został przeniesiony do archiwum.");
                    fetchList();
                })
            }
        })
    }

    const assignProcessEstimateHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        assignProcessEstimateSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                assignProcessEstimate(process.id, estimate.id).then(() => {
                    toast.success("Kosztorys został przypisany.");
                    fetchList();
                });
            }
        })
    }

    return (
        <tr>
            <td>{toDateTimeFormat(estimate.createdAt)}</td>
            <td>
                <div className="d-flex justify-content-between">
                    <Link to={`/estimate/${estimate.id}`}>
                        {estimate.name}
                    </Link>

                    {process.estimate?.id === estimate.id && (
                        <span>
                            <OverlayTrigger
                                overlay={(props) => (
                                    <Tooltip {...props}>Zastosowany kosztorys</Tooltip>
                                )}
                            >
                                <FontAwesomeIcon icon={faStar} fixedWidth={true} className="text-success" />
                            </OverlayTrigger>
                        </span>
                    )}

                </div>
            </td>
            <td className="text-center">{estimate.status.name}</td>
            <td>{`${estimate.createdBy.firstName} ${estimate.createdBy.lastName}`}</td>
            <td className="text-end">
                <NumberFormat value={estimate.priceGross} />
            </td>
            <td className="text-center">{estimate.acceptedAt}</td>
            <td className="text-end" style={{width: '0'}}>
                <Dropdown>
                    <Dropdown.Toggle variant={"outline-cyan"}>
                        <FontAwesomeIcon icon={faEllipsisVertical}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/estimate/${estimate.id}`}>
                            <FontAwesomeIcon icon={faEye} fixedWidth/> Karta
                        </Dropdown.Item>
                        <Dropdown.Item onClick={e => setEstimateEditModal(true)}>
                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                        </Dropdown.Item>
                        <Dropdown.Item onClick={assignProcessEstimateHandler}>
                            <FontAwesomeIcon icon={faStar} fixedWidth/> Ustaw jako główny
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Link} to="#" className="text-danger" onClick={archiveEstimateHandler}>
                            <FontAwesomeIcon icon={faBoxArchive} fixedWidth/> Archiwizuj
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Modal show={showEstimateEditModal} onHide={() => setEstimateEditModal(false)}
                       size={"lg"} centered={true}
                       className="modal-blur"
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5"><FontAwesomeIcon icon={faScrewdriverWrench} fixedWidth={true}/> Edycja kosztorysu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProcessEstimateFormCommon process={process}/>
                        <EstimateEditComponent
                            estimate={estimate}
                            afterSuccessSave={afterSuccessSave}
                        />
                    </Modal.Body>
                </Modal>
            </td>
        </tr>
    )
};

export default ProcessEstimationListItemComponent;