import React, {ReactElement, useState} from "react";
import {ProcessItemType, ProcessType} from "@process/types/process.types";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/pro-regular-svg-icons";
import {faCog, faPen} from "@fortawesome/pro-light-svg-icons";
import {ProcessItemSubstituteCreate, ProcessItemSubstituteEdit} from "@process/components";
import {ProcessItemSubstituteType} from "@process/types/processItem.types";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessItemSubstituteProps {
    processItem: ProcessItemType
}

const ProcessItemSubstituteComponent: React.FC<ProcessItemSubstituteProps> = ({processItem}): ReactElement => {
    const [showSubstitudeFormModal, setShowSubstitudeFormModal] = useState<boolean>(false);
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const afterSubstitudeSave = () => {
        setShowSubstitudeFormModal(false);
        dispatch(refreshProcess());
    }

    const substitute: Maybe<ProcessItemSubstituteType> = processItem?.substitute ?? null;

    return (
        <div>
            {substitute ? (
                <div>
                    <h3 className="text-center mb-0 text-pink bg-purple-lt">
                        ZASTĘPCZY
                    </h3>

                    <dl className="dl-horizontal">
                        <dt>Nazwa/Model:</dt>
                        <dd>
                            <div className="d-flex justify-content-between">
                                <span>{substitute.name}</span>
                                <Button size={"sm"} variant={"ghost-primary"}
                                        onClick={() => setShowSubstitudeFormModal(true)}
                                >
                                    <><FontAwesomeIcon icon={faPen} fixedWidth={true}/> edytuj &raquo;</>
                                </Button>
                            </div>
                        </dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>Numer seryjny:</dt>
                        <dd>{substitute.serialNumber}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>Data wydania:</dt>
                        <dd>{substitute.issueDate}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>Data zwrotu:</dt>
                        <dd>{substitute.returnDate}</dd>
                    </dl>
                    <dl className="dl-horizontal">
                        <dt>Przypisany do:</dt>
                        <dd>{`${substitute.user.firstName} ${substitute.user.lastName}`}</dd>
                    </dl>
                    <dl className="dl-horizontal border-0">
                        <dt>Notatka:</dt>
                        <dd>
                            {!substitute.note ? (
                                <span className="text-muted">- - -</span>
                            ) : (
                                <em style={{whiteSpace: "pre-wrap"}}>{substitute.note}</em>
                            )}
                        </dd>
                    </dl>
                </div>
            ) : (
                <div className="text-center">
                    <Button variant={"outline-cyan"}
                            onClick={e => setShowSubstitudeFormModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} fixedWidth={true}/> Dodaj zastępczne
                    </Button>
                </div>
            )}

            <Modal show={showSubstitudeFormModal} onHide={() => setShowSubstitudeFormModal(false)}
                   size={"lg"} centered={true}
                   className="modal-blur"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5"><FontAwesomeIcon icon={faCog} fixedWidth={true}/> Urządzenie zastępcze</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {substitute ? (
                        <ProcessItemSubstituteEdit process={process} processItem={processItem} afterSuccessSave={afterSubstitudeSave} />
                    ) : (
                        <ProcessItemSubstituteCreate process={process} processItem={processItem} afterSuccessSave={afterSubstitudeSave} />
                    )}
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default ProcessItemSubstituteComponent;