import {ProcessItemSubstituteRequestType, ProcessItemSubstituteType} from "@process/types/processItem.types";


export const mapProcessItemSubstituteTypeToRequestType = (
    processItemSubstitute: ProcessItemSubstituteType
): ProcessItemSubstituteRequestType => {
    return {
        name: processItemSubstitute?.name ?? '',
        serialNumber: processItemSubstitute?.serialNumber ?? '',
        user: processItemSubstitute?.user?.id ?? '',
        issueDate: processItemSubstitute?.issueDate ?? '',
        returnDate: processItemSubstitute?.returnDate ?? '',
        note: processItemSubstitute?.note ?? ''
    }
}