import React, {ReactElement} from "react";
import {ProcessFlowType} from "@process/types/processFlow.types";
import moment from "moment/moment";
import {toDateFormat} from "@src/helpers/utils.helper";


interface ProcessFlowItemProps {
    processFlow: ProcessFlowType
}

const ProcessFlowItem: React.FC<ProcessFlowItemProps> = ({processFlow}): ReactElement => {

    let createdByInitials = '';
    if (processFlow.createdBy.firstName) {
        createdByInitials += processFlow.createdBy.firstName.substring(0,1);
    }
    if (processFlow.createdBy.lastName) {
        createdByInitials += processFlow.createdBy.lastName.substring(0,1);
    }


    return (
        <div className="timeline-item">
            <div className="timeline-icon badge bg-cyan-lt">
                {createdByInitials}
            </div>
            <div className="timeline-time text-muted">
                <div>{moment(processFlow.createdAt).format('YYYY-MM-DD')}</div>
                <div>{moment(processFlow.createdAt).format('HH:mm')}</div>
            </div>
            <div className="timeline-content">
                {processFlow.deadline && (
                    <div>
                        Termin: {toDateFormat(processFlow.deadline)}
                    </div>
                )}

                <div>
                    <span className="text-primary">{processFlow.createdBy.firstName} {processFlow.createdBy.lastName}</span>:
                    &nbsp;

                    {processFlow.changedUser.id !== processFlow.assignedUser.id && (
                        <span>
                            <span>{processFlow.changedUser.firstName} {processFlow.changedUser.lastName}</span>
                            &nbsp;&raquo;&nbsp;
                        </span>
                    )}
                    <span>{processFlow.assignedUser.firstName} {processFlow.assignedUser.lastName}</span>
                </div>
                <div>
                    <span className="badge">{processFlow.changedDivision.name}</span>
                    &nbsp;
                    {processFlow.changedStatus.name}

                    {processFlow.changedStatus.id !== processFlow.assignedStatus.id && (
                        <>
                            &nbsp;&raquo;&nbsp;
                            {processFlow.changedDivision.id !== processFlow.assignedDivision.id && (
                                <span className="badge">{processFlow.assignedDivision.name}</span>
                            )}
                            &nbsp;
                            {processFlow.assignedStatus.name}
                        </>
                    )}
                </div>

                {processFlow.note && (
                    <div className="text-muted timeline-note">
                        <em>{processFlow.note}</em>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProcessFlowItem;