import React, {ReactElement, useEffect} from "react";
import { ProcessItemType, ProcessType} from "@process/types/process.types";
import {ProcessItemSubstituteRequestType, ProcessItemSubstituteType} from "@process/types/processItem.types";
import { Maybe } from "@src/types/global.types";
import {createProcessItemSubstitute, initProcessItemSubstitute} from "@process/utils/axios/processItem";
import {ProcessItemSubstituteForm} from "@process/components";
import {AxiosResponse} from "axios";


interface ProcessItemSubstituteCreateProps {
    process: ProcessType
    processItem: ProcessItemType,
    afterSuccessSave: Function,
}

const ProcessItemSubstituteCreateComponent: React.FC<ProcessItemSubstituteCreateProps> = ({process, processItem, afterSuccessSave}): ReactElement => {
    const [processItemSubstitute, setProcessItemSubstitute] = React.useState<Maybe<ProcessItemSubstituteType>>();

    useEffect(() => {
        initProcessItemSubstitute(process.id, processItem.id)
            .then(setProcessItemSubstitute)

    }, [])

    if (!processItemSubstitute) {
        return;
    }

    const saveProcessItemSubstitute = async (data: ProcessItemSubstituteRequestType): Promise<AxiosResponse> => {
        return createProcessItemSubstitute(process.id, processItem.id, data);
    }

    return (
        <ProcessItemSubstituteForm
            processItemSubstitute={processItemSubstitute}
            saveProcessItemSubstitute={saveProcessItemSubstitute}
            afterSuccessSave={afterSuccessSave}
        />
    );
}

export default ProcessItemSubstituteCreateComponent;
