import React, {ReactElement, useEffect} from "react";
import FilterComponent from "@src/components/Filter/Filter.component";
import FilterSearch from "@src/components/Filter/components/FilterSearch/FilterSearch.component";
import {ProcessFilterRequestType, ProcessFilterType} from "@process/types/process.types";
import {UserSelect} from "@user/components";
import {UserType} from "@user/types/user.types";
import {Maybe} from "@src/types/global.types";
import {DictionaryType} from "@dictionary/types/dictionary.types";
import {mapProcessFilterRequestToProcessFilterType as mapProcessFilter} from "@process/utils/mapper";
import {DictionarySelect} from "@dictionary/components";
import {Col, Row} from "react-bootstrap";
import {ClientSelect} from "@client/components";
import {ClientType} from "@client/types/client.types";
import {DepartmentSelect} from "@department/components";
import {DepartmentType} from "@department/types/department.types";
import {auth, AuthState} from "@auth/store/authSlice";
import {useSelector} from "react-redux";
import {AuthRoles} from "@auth/utils/AuthRoles";
import {ProcessDictionaryEnum} from "@process/types/process.enums";

interface ProcessFilterProps {
    processFilterRequest: ProcessFilterRequestType
}

type FilterAllowTypes = Maybe<UserType|DictionaryType|ClientType|DepartmentType>;

const ProcessFilterComponent:React.FC<ProcessFilterProps> = ({processFilterRequest}): ReactElement => {
    const [filter, setFilter] = React.useState<ProcessFilterType>(mapProcessFilter(processFilterRequest));
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const hasServiceRole = !!authData.roles.find(role => role === AuthRoles.ROLE_SERVICE);
    const hasCustomRole = !!authData.roles.find(role => role === AuthRoles.ROLE_CUSTOM);

    const onFilterChangeHandler = (data: FilterAllowTypes, name: string) => {
        setFilter((prevState: ProcessFilterType) => ({...prevState, [name]: data}));
    }

    useEffect(() => setFilter(mapProcessFilter(processFilterRequest)), [processFilterRequest]);

    return (
        <FilterComponent>
            <Row>
                <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                    <FilterSearch value={filter.search ?? ''} />
                </Col>
                <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                    <UserSelect onChange={onFilterChangeHandler} name="user" value={filter.user} isClearable={true} placeholder="Przypisane do" />
                </Col>
                <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                    <DictionarySelect
                        name="salonStatus"
                        model={ProcessDictionaryEnum.ProcessStatusSalon}
                        onChange={onFilterChangeHandler}
                        value={filter.salonStatus}
                        isClearable={true}
                        placeholder="Status salonu"
                    />
                </Col>
                <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                    <DictionarySelect
                        name="serviceStatus"
                        model={ProcessDictionaryEnum.ProcessStatusService}
                        onChange={onFilterChangeHandler}
                        value={filter.serviceStatus}
                        isClearable={true}
                        placeholder="Status serwisu"
                    />
                </Col>
                <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                    <ClientSelect onChange={onFilterChangeHandler} name="client" value={filter.client} isClearable={true} placeholder="Klient" />
                </Col>
                {(hasAdminRole || hasServiceRole || hasCustomRole) && (
                    <Col xs={12} md={6} lg={4} xl={3} className="mb-3">
                        <DepartmentSelect
                            name="department"
                            onChange={onFilterChangeHandler}
                            value={filter.department}
                            isClearable={true}
                        />
                    </Col>
                )}
            </Row>
        </FilterComponent>
    );
}

export default ProcessFilterComponent;