import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {ProcessType, ProcessItemType} from "@process/types/process.types";
import {Maybe} from "@src/types/global.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleEuro,
    faFiles,
    faImages,
    faTruck,
    faListTimeline,
    faUser,
    faInfoCircle,
    faScrewdriverWrench,
    faClipboardCheck,
    faWarning,
    faArrowProgress,
} from "@fortawesome/pro-light-svg-icons";
import {
    ProcessCustomer, ProcessDetails,
    ProcessDropdownOptions,
    ProcessEstimate,
    ProcessEstimateList,
    ProcessFiles,
    ProcessImages,
    ProcessInfo,
    ProcessItem as ProcessItemComponent,
    ProcessShipment,
    ProcessSummary,
    ProcessFlow
} from "@process/components";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {fetchProcess as fetchProcessState, processState, clearProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";

interface ProcessCardProps {}

const ProcessCardView: React.FC<ProcessCardProps> = () => {
    const {id: processId} = useParams();
    const [activeProcessTab, setActiveProcessTab] = useState<string>('device');
    const process: Maybe<ProcessType> = useSelector(processState);
    const dispatch = useDispatch<AppDispatch>();

    const fetchProcess = () => {
        dispatch(fetchProcessState(processId));
    }

    useEffect(() => {
        fetchProcess();
        return () => {
            dispatch(clearProcess())
        }
    }, [processId]);

    if(!process){
        return null
    }

    return (
        <div className="view-process-card">
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Serwis / Karta serwisu
                        </div>
                        <h2 className="page-title">
                            {process.title}
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row className="mb-3">
                    <Col lg={6}>
                        <div className="card-tabs">
                            <Tabs
                                defaultActiveKey="info"
                                id="uncontrolled-tab-example"
                            >
                                <Tab eventKey="info" className="card"
                                     title={<><FontAwesomeIcon icon={faScrewdriverWrench}
                                                               fixedWidth={true}/> Zgłoszenie</>}
                                >
                                    <Card.Body>
                                        <ProcessInfo/>
                                    </Card.Body>
                                </Tab>

                                <Tab eventKey="customer" className="card"
                                     title={<><FontAwesomeIcon icon={faUser} fixedWidth={true}/> Klient</>}
                                >
                                    <ProcessCustomer/>
                                </Tab>
                                <Tab eventKey="details" className="card"
                                     title={<><FontAwesomeIcon icon={faInfoCircle} fixedWidth={true}/> Szczegóły</>}
                                >
                                    <ProcessDetails/>
                                </Tab>
                            </Tabs>
                            <br/>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <Tabs defaultActiveKey="summary" className="mb-3">
                            <Tab eventKey="summary"
                                 title={<><FontAwesomeIcon icon={faClipboardCheck}
                                                           fixedWidth={true}/> Podsumowanie</>}
                            >
                                <ProcessSummary/>
                            </Tab>
                            <Tab eventKey="esimation"
                                 title={<><FontAwesomeIcon icon={faClipboardCheck}
                                                           fixedWidth={true}/> Wycena</>}
                            >
                                <ProcessEstimate/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <Row className="mb-3 mt-5">
                    <Col>
                        <Tabs
                            defaultActiveKey={activeProcessTab}
                            onSelect={(tab) => setActiveProcessTab(tab)}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="device"
                                 title={<><FontAwesomeIcon icon={faScrewdriverWrench}
                                                           fixedWidth={true}/> Urządzenie</>}
                            >
                                {process.items.map((processItem: ProcessItemType) => (
                                    <ProcessItemComponent
                                        processItem={processItem}
                                        key={processItem.id}
                                    />
                                ))}
                            </Tab>

                            <Tab eventKey="flow"
                                 title={<><FontAwesomeIcon icon={faArrowProgress} fixedWidth={true}/> Przepływ pracy</>}
                            >
                                <ProcessFlow/>
                            </Tab>
                            <Tab eventKey="estimate"
                                 title={<><FontAwesomeIcon icon={faCircleEuro} fixedWidth={true}/> Kosztorysy</>}
                            >
                                <ProcessEstimateList/>
                            </Tab>
                            <Tab eventKey="files"
                                 title={<><FontAwesomeIcon icon={faFiles} fixedWidth={true}/> Dokumenty</>}
                            >
                                <ProcessFiles/>
                            </Tab>
                            <Tab eventKey="images"
                                 title={<><FontAwesomeIcon icon={faImages} fixedWidth={true}/> Zdjęcia</>}
                            >
                                {activeProcessTab == 'images' && <ProcessImages/>}
                            </Tab>
                            <Tab eventKey="shipment"
                                 title={<><FontAwesomeIcon icon={faTruck} fixedWidth={true}/> Dostawa</>}
                            >
                                <ProcessShipment/>
                            </Tab>
{/*                            <Tab eventKey="flow"
                                 title={<><FontAwesomeIcon icon={faListTimeline} fixedWidth={true}/> Historia
                                     zmian</>}
                            >
                                Historia zmian
                            </Tab>*/}
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ProcessCardView;