import React, {useEffect} from "react";
import {Card, Table} from "react-bootstrap";
import {ProcessFileType, ProcessType} from "@process/types/process.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh} from "@fortawesome/pro-regular-svg-icons";
import {ProcessFilesUpload} from "@process/components";
import {fetchProcessFiles} from "@process/utils/axios";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";
import ProcessFileComponent from "./ProcessFile.component";
import {Maybe} from "@src/types/global.types";
import {useSelector} from "react-redux";
import {processState} from "@process/store/processSlice";

interface ProcessFilesProps {
}

const ProcessFilesComponent: React.FC<ProcessFilesProps> = () => {
    const [processFiles, setProcessFiles] = React.useState<ProcessFileType[]>([]);
    const process: Maybe<ProcessType> = useSelector(processState);

    const fetchList = () => {
        fetchProcessFiles(process.id)
            .then((response: AxiosResponse<ProcessFileType[]>) => {
                setProcessFiles(response.data)
            })
            .catch(error => {
                toast.error("Wystąpił błąd :/");
                console.log('Error', error.message);
            })
    }

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <div>
            <ProcessFilesUpload process={process} refresh={fetchList}/>

            <Card>
                <Table responsive hover bordered className="card-table table-vcenter card-table-options">
                    <thead>
                    <tr>
                        <th>Nazwa pliku</th>
                        <th className="text-center">Kategoria</th>
                        <th>Dodany przez</th>
                        <th className="text-center">Data</th>
                        <th className="text-center">Typ</th>
                        <th className="text-end">Rozmiar</th>
                        <th className="text-center" style={{width: '0'}}>
                            <FontAwesomeIcon icon={faRefresh} fixedWidth={true} onClick={fetchList}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {processFiles.map((processFile: ProcessFileType) => (
                        <ProcessFileComponent process={process} processFile={processFile} refresh={fetchList}
                                              key={processFile.id}/>
                    ))}
                    </tbody>
                </Table>
            </Card>
        </div>
    );
};

export default ProcessFilesComponent;