import React, {useEffect, useRef} from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store";
import {auth, AuthState, logout} from "@auth/store/authSlice";

const NavBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const authData: AuthState = useSelector(auth);
    const navbarRef = useRef(null);
    const location = useLocation();

    const logoutHandler = (e) => {
        e.preventDefault();
        dispatch(logout());
    }

    useEffect(() => {
        if (navbarRef.current && window.innerWidth < 992){
            const isExpanded = navbarRef.current.getAttribute("aria-expanded") === "true";
            if (isExpanded) {
                navbarRef.current.click();
            }
        }
    }, [location.pathname])

    return (
        // <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <nav className="navbar navbar-expand-md d-print-none bg-body-tertiary-" style={{backgroundColor: '#f8f9fa'}}>
            <div className="container-xl gap-md-3">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation"
                    ref={navbarRef}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Link to="/" className="navbar-brand">
                    {authData.service?.logo
                        ? <><img src={authData.service.logo} alt={authData.service.name} style={{maxWidth: 110}} /></>
                        : <>OneSpace</>
                    }
                </Link>

                <div className="navbar-nav flex-row order-md-last">
                    {false && (
                        <div className="d-none d-md-flex">
                            <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" data-bs-toggle="tooltip"
                               data-bs-placement="bottom" aria-label="Enable dark mode"
                               data-bs-original-title="Enable dark mode">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="icon icon-1">
                                    <path
                                        d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
                                </svg>
                            </a>
                            <a href="?theme=light" className="nav-link px-0 hide-theme-light-" data-bs-toggle="tooltip"
                               data-bs-placement="bottom" aria-label="Enable light mode"
                               data-bs-original-title="Enable light mode">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="icon icon-1">
                                    <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                    <path
                                        d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                                </svg>
                            </a>
                            <div className="nav-item dropdown d-none d-md-flex me-3">
                                <a href="#" className="nav-link px-0" data-bs-toggle="dropdown"
                                   aria-label="Show notifications">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="icon icon-1">
                                        <path
                                            d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                        <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                    </svg>
                                    <span className="badge bg-red"></span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Last updates</h3>
                                        </div>
                                        <div className="list-group list-group-flush list-group-hoverable">
                                            <div className="list-group-item">
                                                <div className="row align-items-center">
                                                    <div className="col-auto"><span
                                                        className="status-dot status-dot-animated bg-red d-block"></span>
                                                    </div>
                                                    <div className="col text-truncate">
                                                        <a href="#" className="text-body d-block">Example 1</a>
                                                        <div className="d-block text-secondary text-truncate mt-n1">
                                                            Change deprecated html tags to text decoration classes
                                                            (#29604)
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <a href="#" className="list-group-item-actions">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none"
                                                                 stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round" strokeLinejoin="round"
                                                                 className="icon text-muted icon-2">
                                                                <path
                                                                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                           aria-label="Open user menu">
                            <span className="avatar avatar-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" height="24"
                                     width="25" className="tanuki-logo" role="img" aria-hidden="true">
                                  <path fill="#E24329"
                                        d="m24.507 9.5-.034-.09L21.082.562a.896.896 0 0 0-1.694.091l-2.29 7.01H7.825L5.535.653a.898.898 0 0 0-1.694-.09L.451 9.411.416 9.5a6.297 6.297 0 0 0 2.09 7.278l.012.01.03.022 5.16 3.867 2.56 1.935 1.554 1.176a1.051 1.051 0 0 0 1.268 0l1.555-1.176 2.56-1.935 5.197-3.89.014-.01A6.297 6.297 0 0 0 24.507 9.5Z"
                                        className="tanuki-shape tanuki"></path>
                                  <path fill="#FC6D26"
                                        d="m24.507 9.5-.034-.09a11.44 11.44 0 0 0-4.56 2.051l-7.447 5.632 4.742 3.584 5.197-3.89.014-.01A6.297 6.297 0 0 0 24.507 9.5Z"
                                        className="tanuki-shape right-cheek"></path>
                                  <path fill="#FCA326"
                                        d="m7.707 20.677 2.56 1.935 1.555 1.176a1.051 1.051 0 0 0 1.268 0l1.555-1.176 2.56-1.935-4.743-3.584-4.755 3.584Z"
                                        className="tanuki-shape chin"></path>
                                  <path fill="#FC6D26"
                                        d="M5.01 11.461a11.43 11.43 0 0 0-4.56-2.05L.416 9.5a6.297 6.297 0 0 0 2.09 7.278l.012.01.03.022 5.16 3.867 4.745-3.584-7.444-5.632Z"
                                        className="tanuki-shape left-cheek"></path>
                                </svg>
                            </span>
                            {authData.user && (
                                <div className="d-none d-xl-block ps-2">
                                    <div>{authData.user.firstName} {authData.user.lastName}</div>
                                    <div className="mt-1 small text-secondary">{authData.user.department}</div>
                                </div>
                            )}
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            {/*<a href="#" className="dropdown-item">Status</a>*/}
                            {/*<a href="./profile.html" className="dropdown-item">Profile</a>*/}
                            {/*<a href="#" className="dropdown-item">Feedback</a>*/}
                            {/*<div className="dropdown-divider"></div>*/}
                            {/*<a href="./settings.html" className="dropdown-item">Settings</a>*/}
                            <a href="#" onClick={logoutHandler}
                               className="dropdown-item">Wyloguj</a>
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to={"/"}>Dashboard</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link" to="/user">Użytkownicy</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link" to="/client">Klienci</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/offer">Oferty</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link" to="/process">Serwis</NavLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </nav>
    );
}

export default NavBar;