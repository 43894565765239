import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons";
import {getUserCollection} from "@user/utils/axios";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {UserFilterRequestType, UserType} from "@user/types/user.types";
import {Col, Row} from "react-bootstrap";
import {AuthRoles} from "@src/modules/auth/utils/AuthRoles";
import {auth, AuthState} from "@src/modules/auth/store/authSlice";
import {useSelector} from "react-redux";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {UserFilter, UserTable} from "@user/components";
import {NotFound} from "@src/components";

interface UserListViewProps {}

const UserListView:React.FC<UserListViewProps> = () => {
    const locationParams = useLocation().search;
    const [userCollection, setUserCollection] = useState<UserType[]>([])
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const [userFilter, setUserFilter] = useState<UserFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [isLoad, setLoad] = useState<boolean>(false);

    const fetchUserCollection = () => {
        getUserCollection(userFilter).then((paginationData: PaginationDataType<UserType>) => {
            setUserCollection(paginationData.items);
            setPagination(paginationData.pagination)
        }).finally(() => {
            setLoad(true)
        });
    }

    useEffect(() => {
        const newUserFilter: UserFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newUserFilter) !== JSON.stringify(userFilter)) {
            setUserFilter(newUserFilter);
        }
    }, [locationParams])

    useEffect(() => {
        fetchUserCollection();
    }, [userFilter]);


    return (
        <div className="view-user-list">

            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Użytkownicy
                        </div>
                        <h2 className="page-title">
                            Lista użytkowników
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            {hasAdminRole && (
                                <Link to="/user/create" className="btn btn-primary">
                                    <FontAwesomeIcon icon={faUserPlus} fixedWidth={true}
                                    /> <span className="d-none d-sm-inline">
                                        Dodaj użytkownika
                                    </span>
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">

                <UserFilter userFilterRequest={userFilter} />
                {userCollection.length === 0 && isLoad && (
                    <div className="text-center">
                        <NotFound/>
                    </div>
                )}

                {userCollection.length > 0 && (
                    <UserTable
                        userCollection={userCollection}
                        userFilter={userFilter}
                        pagination={pagination}
                    />
                )}
            </div>
        </div>
    );
}

export default UserListView;