import {ClientFilterRequestType, ClientType} from "@client/types/client.types";
import {SortableColumn} from "@src/components";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";

const columnHelper = createColumnHelper<ClientType>()

export const getClientColumn = (clientFilter: ClientFilterRequestType) => {
    return [
        columnHelper.accessor(client => client.createdAt, {
            id: 'createdAt',
            header: () => <SortableColumn label="Dodano" orderKey="createdAt" params={clientFilter}/>,
            cell: info => moment(info.getValue()).format('YYYY-MM-DD HH:mm')
        }),
        columnHelper.accessor(client => client, {
            id: 'lastName',
            cell: info => <Link to={`/client/${info.getValue().id}`}>
                <div>{`${info.getValue().firstName ?? ''} ${info.getValue().lastName ?? ''}`}</div>
                <div>{info.getValue().companyName ?? ''}</div>
            </Link>,
            header: () => <SortableColumn label="Nazwa" orderKey="name" params={clientFilter}/>,
        }),
        columnHelper.accessor(client => client.nip, {
            id: 'nip',
            header: 'NIP',
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor(client => client.email, {
            id: 'email',
            header: () => <SortableColumn label="E-mail" orderKey="email" params={clientFilter}/>,
            cell: info => info.renderValue(),
        }),
        columnHelper.accessor(client => client.phone, {
            id: 'phone',
            header: 'Telefon',
        }),
        columnHelper.accessor(client => client, {
            id: 'address',
            header: () => <SortableColumn label="Adres" orderKey="city" params={clientFilter}/>,
            cell: info => <>
                <div>{info.getValue().street}</div>
                <div>{info.getValue().postCode}&nbsp;{info.getValue().city}</div>
            </>
        })
    ]
}