import {client as clientAxios} from "@src/library/axios.library";
import {AxiosResponse} from "axios";
import {EstimateFilterType, EstimateFormType, EstimateType} from "@estimate/types/estimate.types";
import {EstimateItemRequestType} from "@estimate/types/estimateItem.types";

export const getEstimateList = async (filterData: EstimateFilterType): Promise<EstimateType[]> => {
    const {data} = await clientAxios.post<EstimateFilterType, AxiosResponse<EstimateType[]>>('/api/v1/estimate', filterData);
    return data;
}

export const getEstimate = async (id: string): Promise<EstimateType> => {
    const {data} = await clientAxios.get<never, AxiosResponse<EstimateType>>('/api/v1/estimate/' + id);
    return data;
}

export const createEstimate = async (data: EstimateFormType): Promise<AxiosResponse> => {
    return await clientAxios.post<EstimateFormType, AxiosResponse>(`/api/v1/estimate/create`, data);
}

export const updateEstimate = async (estimateId: string, data: EstimateFormType): Promise<AxiosResponse> => {
    return await clientAxios.patch<EstimateFormType, AxiosResponse>(`/api/v1/estimate/${estimateId}/update`, data);
}

export const archiveEstimate = async (estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.patch<never, AxiosResponse>(`/api/v1/estimate/${estimateId}/archive`);
}

export const deleteEstimate = async (estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.delete<never, AxiosResponse>(`/api/v1/estimate/${estimateId}/delete`);
}

export const createEstimateItem = async (estimateId: string, data: EstimateItemRequestType): Promise<AxiosResponse> => {
    return await clientAxios.post<EstimateItemRequestType, AxiosResponse>(`/api/v1/estimate/${estimateId}/item/create`, data);
}

export const updateEstimateItem = async (estimateId: string, estimateItemId: string, data: EstimateItemRequestType): Promise<AxiosResponse> => {
    return await clientAxios.patch<EstimateItemRequestType, AxiosResponse>(`/api/v1/estimate/${estimateId}/item/${estimateItemId}/update`, data);
}

export const deleteEstimateItem = async (estimateId: string, estimateItemId: string): Promise<AxiosResponse> => {
    return await clientAxios.delete<never, AxiosResponse>(`/api/v1/estimate/${estimateId}/item/${estimateItemId}/delete`);
}

export const downloadEstimateFile = async (estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.get<never, AxiosResponse>('/api/v1/estimate/' + estimateId + '/download', {
        responseType: 'blob',
    });
}

export const acceptEstimate = async (estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.patch<never, AxiosResponse>(`/api/v1/estimate/${estimateId}/accept`);
}

export const rejectEstimate = async (estimateId: string): Promise<AxiosResponse> => {
    return await clientAxios.patch<never, AxiosResponse>(`/api/v1/estimate/${estimateId}/reject`);
}