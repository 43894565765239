import React, {ReactElement, useMemo} from "react";
import Table from "react-bootstrap/Table";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faEye, faGear, faPen} from "@fortawesome/pro-regular-svg-icons";
import {Card, Dropdown} from "react-bootstrap";
import {Pagination} from "@src/components";
import {UserTableProps} from "./UserTable.types";
import {getUserColumn} from "./UserTable.column";
import {Link} from "react-router-dom";
import {AuthRoles} from "@auth/utils/AuthRoles";
import {auth, AuthState} from "@auth/store/authSlice";
import {useSelector} from "react-redux";

const UserTable: React.FC<UserTableProps> = (
    {userCollection, userFilter, pagination}
): ReactElement => {
    const authData: AuthState = useSelector(auth);
    const hasAdminRole = !!authData.roles.find(role => role === AuthRoles.ROLE_ADMIN);
    const tableColumns = useMemo(() => getUserColumn(userFilter), [userFilter]);
    const table = useReactTable({
        data: userCollection,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <Card>
            <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} scope="col" {...header.column.columnDef.meta}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </th>
                            ))}
                            <th className="text-center" style={{width: 0}}>
                                <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} {...cell.column.columnDef.meta}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                        <td className="text-center">
                            <Dropdown>
                                <Dropdown.Toggle variant={"outline-cyan"}>
                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`/user/${row.original.id}`}>
                                        <FontAwesomeIcon icon={faEye} fixedWidth/> Karta
                                    </Dropdown.Item>
                                    {hasAdminRole && (
                                        <Dropdown.Item as={Link} to={`/user/edit/${row.original.id}`}>
                                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {pagination && (
                <Card.Footer>
                    <Pagination pagination={pagination}/>
                </Card.Footer>
            )}
        </Card>
    );
}

export default UserTable;