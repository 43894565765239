import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {
    ChangePasswordFormErrorType, ChangePasswordFormType,
} from "@auth/types/auth.types";
import {changePassword} from "@auth/utils/axios";
import {Link, useParams} from "react-router-dom";
import {FormError} from "@src/components";

interface ChangePasswordProps {
}

const ChangePasswordComponent: React.FC<ChangePasswordProps> = () => {
    const {token} = useParams();
    const [isSending, setSending] = React.useState<boolean>(false);
    const [isChanged, setChanged] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState<ChangePasswordFormType>({});
    const [formErrors, setFormErrors] = useState<ChangePasswordFormErrorType>({});

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setFormData(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);

        changePassword(token, formData)
            .then(() => { setChanged(true); })
            .catch(error => { setFormErrors(error.response?.data?.errors || {}) })
            .finally(() => setSending(false))
    }

    if (isChanged) {
        return (
            <div className="change-password-component text-center">
                <div className="mb-5">Hasło zostało zmienione.</div>
                <Link to="/" className="w-100 btn btn-primary" >
                    Przejdź do logowania
                </Link>
            </div>
        );
    }

    return (
        <div className="change-password-component">

            {formErrors.token && (
                <Alert variant="danger">
                    {formErrors.token.map((error, key) => <div key={key}>{error.message}</div>)}
                </Alert>
            )}

            <Form onSubmit={onSubmitHandler}>

                <Form.Group className="mb-3">
                    <Form.Label className="required">Nowe hasło:</Form.Label>
                    <Form.Control
                        type="password"
                        value={formData.password ?? ''}
                        onChange={onChangeHandler}
                        name="password"
                        isInvalid={!!formErrors.password}
                    />
                    <FormError errors={formErrors.password} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="required">Powtórz hasło:</Form.Label>
                    <Form.Control
                        type="password"
                        value={formData.passwordRepeat ?? ''}
                        onChange={onChangeHandler}
                        name="passwordRepeat"
                        isInvalid={!!formErrors.passwordRepeat}
                    />
                    <FormError errors={formErrors.passwordRepeat} />
                </Form.Group>

                <div className="form-footer">
                    <Button type="submit" className="btn btn-primary w-100">
                        {isSending ? 'Zapisuje...' : 'Zmień hasło'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default ChangePasswordComponent