import React, {useEffect, useState} from "react";
import { ProcessType } from "@process/types/process.types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faListRadio, faPhone, faInfoCircle} from "@fortawesome/pro-light-svg-icons";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ProcessStatusForm} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {useDispatch, useSelector} from "react-redux";
import {processState, refreshProcess} from "@process/store/processSlice";
import {AppDispatch} from "@src/store";
import {DictionaryDivisionEnum, DictionaryType} from "@dictionary/types/dictionary.types";

interface ProcessInfoProps{
}

const ProcessInfoComponent: React.FC<ProcessInfoProps> = () => {

    const [showProcessChangeStatusModal, setShowProcessChangeStatusModal] = useState<boolean>(false);
    const process: Maybe<ProcessType> = useSelector(processState);
    const [divisionKey, setDivisionKey] = useState<Maybe<string>>(process.division?.key);
    const dispatch = useDispatch<AppDispatch>();

    const afterProcessStatusSave = () => {
        dispatch(refreshProcess());
        setShowProcessChangeStatusModal(false);
    }

    useEffect(() => setDivisionKey(process.division?.key), [process]);

    return (
        <>
            <dl className="dl-horizontal">
                {/*<dt>Number zgłoszenia:</dt>*/}
                <dd>
                    <h3 className="fs-2 fw-normal m-0">
                        <Link to={`/process/${process.id}`}>
                            {process.number}
                        </Link>
                    </h3>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Klient:</dt>
                <dd>
                    <div>
                        <Link
                            to={process?.customer?.client ? `/client/${process.customer.client.id}` : '#'}
                        >
                            {process.customer.firstName} {process.customer.lastName}
                        </Link>
                    </div>
                    {process.customer.companyName && (
                        <div>{process.customer.companyName}</div>
                    )}

                    <div className="mt-1">
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.email}>
                            {process.customer.email}
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faPhone} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.phone}>
                            {process.customer.phone}
                        </a>
                    </div>
                </dd>
            </dl>
            {process.department && (
                <dl className="dl-horizontal">
                    <dt>Oddział:</dt>
                    <dd>{process.department.code} - {process.department.name}</dd>
                </dl>
            )}
            <dl className="dl-horizontal border-0">
                <dt>Data przyjęcia:</dt>
                <dd>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>{process.date}</div>
                        <div className="fst-italic small text-muted">{process.createdAt}</div>
                    </div>
                </dd>
            </dl>

            <br/>

            <dl className="dl-horizontal">
                <dt>
                    Przypisane do:&nbsp;
                    <OverlayTrigger
                        overlay={(props) => (
                            <Tooltip {...props}>Osoba która w tej chwili jest odpowiedzialna za obsługę urządzenia</Tooltip>
                        )}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth={true} className="text-primary" />
                    </OverlayTrigger>
                </dt>
                <dd className="d-sm-flex gap-md-3 justify-content-sm-between">
                    <div>
                        <span className="badge">{process.division.name}</span>
                        &nbsp;
                        {process.user.firstName} {process.user.lastName}
                    </div>

                    <Link to={"#"} className="text-primary text-nowrap col-auto"
                          onClick={(e) => { e.preventDefault(); setShowProcessChangeStatusModal(true)}}
                    >zmień osobę &raquo;</Link>

                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Salon:</dt>
                <dd className="d-sm-flex gap-md-3 justify-content-sm-between">
                    <div>
                        {process.salonStatus?.name ?? <span className="text-muted">- - -</span>}
                        {process.salonUser && (
                            <>
                                <span className="text-muted"> &raquo; </span>{process.salonUser?.firstName + ' ' + process.salonUser?.lastName}
                            </>
                        )}
                    </div>
                    <div className="text-nowrap col-auto">
                        <Link to={"#"} className="text-primary"
                              onClick={(e) => {
                                  e.preventDefault();
                                  setDivisionKey(DictionaryDivisionEnum.Salon)
                                  setShowProcessChangeStatusModal(true);
                              }}
                        >zmień status &raquo;</Link>
                    </div>
                </dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Serwis:</dt>
                <dd className="d-flex justify-content-between">
                    <div>
                        {process.serviceStatus?.name ?? <span className="text-muted">- - -</span>}
                        {process.serviceUser && (
                            <>
                                <span className="text-muted"> &raquo; </span>{process.serviceUser?.firstName + ' ' + process.serviceUser?.lastName}
                            </>
                        )}
                    </div>
                    <div className="text-nowrap col-auto">
                        <Link to={"#"} className="text-primary"
                              onClick={(e) => {
                                  e.preventDefault();
                                  setDivisionKey(DictionaryDivisionEnum.Service)
                                  setShowProcessChangeStatusModal(true);
                              }}
                        >zmień status &raquo;</Link>
                    </div>
                </dd>
            </dl>

            {showProcessChangeStatusModal && (
                <Modal show={showProcessChangeStatusModal} onHide={() => setShowProcessChangeStatusModal(false)}
                       size={"lg"} centered={true}
                       className="modal-blur"
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5"><FontAwesomeIcon icon={faListRadio} fixedWidth={true}/> Zmiana statusu w procesie</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProcessStatusForm
                            process={process}
                            divisionKey={divisionKey}
                            afterSave={afterProcessStatusSave}
                        />
                    </Modal.Body>
            </Modal>
            )}
        </>
    );
};

export default ProcessInfoComponent;