import {Maybe} from "@src/types/global.types";

export enum DictionaryEnum {
    Tax = 'Tax',
    Unit = 'Unit',
    Division = 'Division',
}

export enum DictionaryDivisionEnum {
    Salon = 'salon',
    Service = 'service',
}

export type DictionaryType = {
    id?: Maybe<string>
    model?: Maybe<string>
    name?: Maybe<string>
    key?: Maybe<string>
    position?: Maybe<number>
    default?: Maybe<boolean>
    closed?: Maybe<boolean>
}

export type DictionaryFilterType = {
    model: string
}
