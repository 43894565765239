import React, {ReactElement, useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/pro-regular-svg-icons";
import {ClientFilterRequestType, ClientType} from "@client/types/client.types";
import {ClientFilter, ClientTable} from "@client/components";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {getClientCollection} from "@client/utils/axios";
import {NotFound} from "@src/components";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {Col, Row} from "react-bootstrap";

interface ClientListProps {
}

const ClientListView: React.FC<ClientListProps> = (): ReactElement => {
    const locationParams = useLocation().search;
    const [clientCollection, setClientCollection] = useState<ClientType[]>([]);
    const [clientFilter, setClientFilter] = useState<ClientFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [isLoad, setLoad] = useState<boolean>(false);

    const fetchClientCollection = () => {
        getClientCollection(clientFilter).then((paginationData: PaginationDataType<ClientType>) => {
            setClientCollection(paginationData.items);
            setPagination(paginationData.pagination);
            setLoad(true);
        });
    }

    useEffect(() => {
        const newClientFilter: ClientFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newClientFilter) !== JSON.stringify(clientFilter)) {
            setClientFilter(newClientFilter);
        }
    }, [locationParams])

    useEffect(fetchClientCollection, [clientFilter]);
    useEffect(() => { window.scrollTo(0,0); }, [clientCollection]);

    return (
        <>
            <div className="page-header">
                <Row className="align-items-center">
                    <Col>
                        <div className="page-pretitle">
                            Klienci
                        </div>
                        <h2 className="page-title">
                            Lista klientów
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <span className="d-none d-sm-inline-">
                                <a href="#" className="btn btn-1">
                                    <FontAwesomeIcon icon={faSearch} fixedWidth={true}/> Szukaj
                                </a>
                            </span>
                            <Link to="/client/create" className="btn btn-primary">
                                <FontAwesomeIcon icon={faPlus} fixedWidth={true}
                                /> <span className="d-none d-sm-inline">
                                    Dodaj klienta
                                </span>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="page-body">
                <ClientFilter clientFilterRequest={clientFilter}/>

                {clientCollection.length === 0 && isLoad && (
                    <div className="text-center">
                        <NotFound />
                    </div>
                )}

                {clientCollection.length > 0 && (
                    <ClientTable
                        clientCollection={clientCollection}
                        clientFilter={clientFilter}
                        pagination={pagination}
                    />
                )}
            </div>
        </>
    );
}

export default ClientListView;