import React from "react";
import { ProcessType } from "@process/types/process.types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/pro-light-svg-icons";

interface ProcessInfoProps{
    process: ProcessType,
}

const EstimateProcessInfoComponent: React.FC<ProcessInfoProps> = ({process}) => {
    return (
        <>
            <dl className="dl-horizontal">
                <dd>
                    <Link to={`/process/${process.id}`}>
                        <h3 className="fs-2 fw-normal m-0">
                            {process.number}
                        </h3>
                    </Link>
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Klient:</dt>
                <dd>
                    <div>
                        {process?.customer?.client
                            ? <Link to={`/client/${process.customer.client.id}`}>
                                {process.customer.firstName} {process.customer.lastName}
                              </Link>
                            : <>{process.customer.firstName} {process.customer.lastName}</>
                        }
                    </div>
                    {process.customer.companyName && (
                        <div>{process.customer.companyName}</div>
                    )}

                    <div className="mt-1">
                        <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.email}>
                            {process.customer.email}
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faPhone} fixedWidth={true}/>&nbsp;
                        <a href={"mailto:" + process.customer.phone}>
                            {process.customer.phone}
                        </a>
                    </div>
                </dd>
            </dl>
            {process.department && (
                <dl className="dl-horizontal">
                    <dt>Oddział:</dt>
                    <dd>{process.department.code} - {process.department.name}</dd>
                </dl>
            )}
            <dl className="dl-horizontal">
                <dt>Data przyjęcia:</dt>
                <dd>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>{process.date}</div>
                        <div className="fst-italic small text-muted">{process.createdAt}</div>
                    </div>
                </dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Przypisane do:</dt>
                <dd>{process.user.firstName} {process.user.lastName}</dd>
            </dl>

            <br/>
            <dl className="dl-horizontal">
                <dt>Status salonu:</dt>
                <dd>
                    {process.salonStatus?.name ?? <span className="text-muted">- - -</span>}
                    {process.salonUser && (
                        <>
                            <span className="text-muted"> &raquo; </span>{process.salonUser?.firstName + ' ' + process.salonUser?.lastName}
                        </>
                    )}
                </dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Status serwisu:</dt>
                <dd>
                    {process.serviceStatus?.name ?? <span className="text-muted">- - -</span>}
                    {process.serviceUser && (
                        <>
                            <span className="text-muted"> &raquo; </span>{process.serviceUser?.firstName + ' ' + process.serviceUser?.lastName}
                        </>
                    )}
                </dd>
            </dl>
        </>
    );
};

export default EstimateProcessInfoComponent;