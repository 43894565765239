import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {
    ProcessType,
    ProcessItemType
} from "@process/types/process.types";
import {getProcess, updateProcess} from "@process/utils/axios";
import {useParams} from "react-router-dom";
import {ProcessDropdownOptions, ProcessForm} from "@process/components";
import {Maybe} from "@src/types/global.types";
import {ClientRequestType} from "@client/types/client.types";
import {AxiosResponse} from "axios";

interface ProcessEditProps {
}

const ProcessEditView: React.FC<ProcessEditProps> = () => {
    const [process, setProcess] = useState<Maybe<ProcessType>>();
    const {id: processId} = useParams();

    useEffect(() => {
        getProcess(processId).then((process: ProcessType) => {
            let processItems: ProcessItemType[] = [];
            process.items.map(processItem => processItems.push({
                id: processItem.id,
                name: processItem.name,
                serialNumber: processItem.serialNumber,
                note: processItem.note,
            }));

            setProcess(process);
        });
    }, []);

    const saveProcess = async (data: ClientRequestType): Promise<AxiosResponse> => {
        return updateProcess(process.id, data);
    }

    if (!process) {
        return null;
    }

    return (
        <div className="view-process-create">

            <div className="page-header mb-5">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Serwis // Edycja
                        </div>
                        <h2 className="page-title">
                            {process.title ?? ""}
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto d-print-none">
                        <div className="btn-list-">
                            <ProcessDropdownOptions process={process} buttonVariant="outline-primary"/>
                        </div>
                    </Col>

                </Row>
            </div>

            <div className="page-body">
                <ProcessForm process={process} saveProcess={saveProcess}/>
            </div>
        </div>
    )
};

export default ProcessEditView;