import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProcessType} from "@process/types/process.types";
import {RootState} from "@src/store";
import {Maybe} from "@src/types/global.types";
import {getProcess as getProcessApi} from "@process/utils/axios";
import {setAppLoading} from "@layout/store/appSlice";

export interface ProcessStateType {
    process: Maybe<ProcessType>,
}

const initialState: ProcessStateType = {
    process: null,
}

export const fetchProcess = createAsyncThunk(
    'process/fetch',
    async (processId: string, thunkAPI): Promise<ProcessType> => {
        return await getProcessApi(processId);
    }
);

export const refreshProcess = createAsyncThunk(
    'process/refresh',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const processId = state.process.process?.id;

        if (processId) {
            return thunkAPI.dispatch(fetchProcess(processId));
        }
    }
);

export const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        setProcess: (state, action: PayloadAction<Maybe<ProcessType>>) => {
            state.process = action.payload;
        },
        clearProcess: (state) => {
            state.process = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProcess.fulfilled, (state, action: PayloadAction<ProcessType>) => {
            state.process = action.payload;
        })
    },
})

export const processState = (state: RootState): ProcessType => state.process.process;
export const { setProcess, clearProcess} = processSlice.actions
export default processSlice.reducer