import React, {useState} from "react";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {ProductSelect} from "@product/components";
import {EstimateItemFormErrorType, EstimateItemRequestType, EstimateItemType} from "@estimate/types/estimateItem.types";
import {ProductType} from "@product/types/product.types";
import {createEstimateItem} from "@estimate/utils/axios";
import {EstimateType} from "@estimate/types/estimate.types";
import {mapEstimateItemToRequestType} from "@estimate/utils/mapper";
import {FormError} from "@src/components";

interface EstimateItemFormInlineProps{
    estimate: EstimateType,
    fetchEstimate: Function
}

const estimateItemDefault: EstimateItemType = {
    quantity: 1
}

const EstimateItemFormInlineComponent: React.FC<EstimateItemFormInlineProps> = (
    {estimate, fetchEstimate}
): React.ReactElement => {
    const [formErrors, setFormErrors] = useState<EstimateItemFormErrorType>({});
    const [estimateItem, setEstimateItem] = useState<EstimateItemType>({...estimateItemDefault});

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        setEstimateItem(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        createEstimateItem(estimate.id, mapEstimateItemToRequestType(estimateItem))
            .then(()=> {
                fetchEstimate();
                setEstimateItem({...estimateItemDefault});
                setFormErrors({})
            })
            .catch(error => {
                setFormErrors(error.response?.data?.errors || {});
            })
    }

    const onProductChangeHandler = (product: ProductType) => {
        setEstimateItem((prevState: EstimateItemType) => {
            return {...prevState,
                product: product,
                name: product.name,
                sku: product.sku,
                unitPriceGross: product.priceGross,
            }
        });
    }

    return (
        <>
            <Form onSubmit={onSubmitHandler} >
                <Row>
                    <Col lg={7} xs={12} className="mb-3">
                        {/*<Form.Label className="required">Produkt:</Form.Label>*/}
                        <ProductSelect
                            value={estimateItem.product}
                            onChange={onProductChangeHandler}
                            className={(formErrors.product || formErrors.name) ?  ' is-invalid' : ''}
                            placeholder={"Wyszukaj aby dodać produkt..."}
                        />
                        <FormError errors={formErrors.product} />
                        <FormError errors={formErrors.name} />
                    </Col>

                    <Col lg={2} sm={4} xs={12} className="mb-3">
                        {/*<Form.Label className="required">Ilość:</Form.Label>*/}
                        <Form.Control
                            value={estimateItem.quantity}
                            onChange={onChangeHandler}
                            name="quantity"
                            placeholder="Ilość"
                            isInvalid={!!formErrors.quantity}
                        />
                        <FormError errors={formErrors.quantity} />
                    </Col>

                    <Col lg={2} sm={4} xs={12} className="mb-3">
                        {/*<Form.Label className="required">Cena jed. brutto</Form.Label>*/}
                        <InputGroup>
                            <Form.Control
                                value={estimateItem.unitPriceGross ?? ''}
                                onChange={onChangeHandler}
                                name="unitPriceGross"
                                placeholder="Cena jed. brutto"
                                className={"text-end "+ (formErrors.unitPriceGross ?  ' is-invalid' : '')}
                            />
                            <InputGroup.Text id="basic-addon1">zł</InputGroup.Text>
                            <FormError errors={formErrors.unitPriceGross} />
                        </InputGroup>
                    </Col>

                    <Col lg={1} sm={4} xs={12} className="mb-3">
                        <Button type="submit" style={{width:'100%'}}>
                            Dodaj
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )

}

export default EstimateItemFormInlineComponent;