import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Maybe, PaginationDataType} from "@src/types/global.types";
import {getClient, getClientCollection} from "@client/utils/axios";
import {ClientFilterType, ClientType} from "@client/types/client.types";
import {OnChangeValue} from "react-select/dist/declarations/src/types";

interface ClientSelectProps {
    onChange: Function,
    name?: Maybe<string>,
    value?: Maybe<ClientType>,
    required?: boolean,
    className?: Maybe<string>,
    isClearable?: Maybe<boolean>,
    placeholder?: Maybe<string>,
}

const ClientSelectComponent: React.FC<ClientSelectProps> = (
    {onChange, value: initClient, name, className, isClearable, placeholder}
) => {
    const [selectOptions, setSelectOptions] = useState<ClientType[]>([]);
    const [clientCollection, setClientCollection] = useState<ClientType[]>([]);
    const [selectedClient, setSelectedClient] = useState<Maybe<ClientType>>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);


    const fetchClientCollection = () => {
        const filter: ClientFilterType = {search: searchValue}
        setLoading(true);
        getClientCollection(filter)
            .then((response: PaginationDataType<ClientType>) => setClientCollection(response.items))
            .finally(() => setLoading(false))
        ;
    }

    const fetchSelectedUser = () => {
        if (!(initClient?.id ?? null)) {
            setSelectedClient(null);
            return;
        }

        if (initClient && selectedClient && initClient.id === selectedClient.id) {
            const selectedUserOK = Object.keys(selectedClient).length > 1;
            if (selectedUserOK) {
                return;
            }
        }

        const initUserOK = Object.keys(initClient).length > 1;
        if (initUserOK) {
            setSelectedClient(initClient);
            return;
        }

        const findUser: ClientType = clientCollection.find((user: ClientType) => user.id === initClient.id) ?? null;
        if (findUser) {
            setSelectedClient(findUser);
            return;
        }

        setLoading(true);
        getClient(initClient.id)
            .then((responseClient: ClientType) => setSelectedClient(responseClient))
            .finally(() => setLoading(false))
    }

    const onChangeHandler = (client: OnChangeValue<ClientType, false>) => {
        setSelectedClient(client);
        onChange(client, name);
    }

    const prepareSelectOptions = () => {
        const options: ClientType[] = [];
        if (selectedClient?.id) {
            const selectedIndex: number = clientCollection.findIndex((user: ClientType) => user.id === selectedClient.id);
            if (0 > selectedIndex) {
                options.push(selectedClient);
            }
        }
        options.push(...clientCollection);
        setSelectOptions(options);
    }

    useEffect(() => {
        const timer = setTimeout(fetchClientCollection, 250);
        return () => clearTimeout(timer);
    }, [searchValue]);

    useEffect(prepareSelectOptions, [clientCollection, selectedClient]);
    useEffect(fetchSelectedUser, [initClient]);


    const getClientOptionValue = (client: ClientType): string => {
        let value = `${client.firstName ?? ''} ${client.lastName ?? ''} ${client.companyName ?? ''}`;
        if (client.nip) {
            value = `${value} # NIP: ${client.nip}`;
        }

        return value;
    }

    return (
        <Select
            options={selectOptions}
            isClearable={isClearable}
            name={name}
            value={selectedClient}
            className={'p-0 form-select ' + (className ? ` ${className}` : '')}
            getOptionLabel={(client: ClientType) => getClientOptionValue(client)}
            getOptionValue={(client: ClientType) => client.id}
            inputValue={searchValue}
            onChange={onChangeHandler}
            onInputChange={value => setSearchValue(value)}
            placeholder={placeholder}
            isLoading={isLoading}
            filterOption={() => true}
            loadingMessage={() => "Szukam..."}
            noOptionsMessage={() => "Brak wyników"}
            styles={{
                control: (baseStyles) => ({...baseStyles, width: 'auto', border: '0', minHeight: '0'}),
                dropdownIndicator: (baseStyles) => ({...baseStyles, padding: '0 8px'}),
                clearIndicator: (baseStyles) => ({...baseStyles, padding: '6px 8px'}),
            }}
        />
    );
}

export default ClientSelectComponent;
