import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {UserForm} from "@user/components";
import {UserType} from "@user/types/user.types";
import {Col, Row} from "react-bootstrap";
import {getUser} from "@user/utils/axios";
import {Maybe} from "@src/types/global.types";

interface UserEditProps {}

const UserEditView: React.FC<UserEditProps> = () => {
    const { id } = useParams();
    const [user, setUser] = useState<Maybe<UserType>>();

    const fetchUser = () => {
        getUser(id).then((user: UserType) => {
            setUser(user);
        });
    }

    useEffect(() => {
        fetchUser()
    }, []);

    if (!user) {
        return null;
    }

    return (
        <div className="view-user-edit">

            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Użytkownicy / Edycja użytkownika
                        </div>
                        <h2 className="page-title">
                            {user.firstName} {user.lastName}
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row>
                    <Col sm md={{span: 8, offset: 2}} >
                        <UserForm user={user} />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default UserEditView;
