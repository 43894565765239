import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {ClientCard, ClientDropdownOptions} from "@client/components";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Row} from "react-bootstrap";
import {ClientType} from "@client/types/client.types";
import {getClient} from "@client/utils/axios";
import {Maybe} from "@src/types/global.types";

const ClientCardView = () => {
    const { id } = useParams();

    const [client, setClient] = useState<Maybe<ClientType>>();

    useEffect(() => {
        getClient(id).then((client: ClientType) => setClient(client));
    }, []);

    if (!client) {
        return null;
    }

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Klienci / Karta klienta
                        </div>
                        <h2 className="page-title">
                            {client.firstName} {client.lastName} {client.companyName}
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <Col xs="auto" className="d-none d-sm-inline">
                                <Link to={`/offer/create?client=${client.id}`} className="btn btn-outline-primary">
                                    <FontAwesomeIcon icon={faPlus} fixedWidth /> Dodaj ofertę
                                </Link>
                            </Col>
                            <Col xs="auto">
                                <ClientDropdownOptions client={client} />
                            </Col>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row>
                    <Col lg={6}>
                        <ClientCard client={client}/>
                    </Col>
                </Row>
            </div>
        </>
    );

}

export default ClientCardView;