import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "@src/store";
import {client} from "@src/library/axios.library";
import {jwtDecode, JwtPayload} from "jwt-decode";
import {Maybe} from "@src/types/global.types";

export interface AuthState {
    isInit: boolean;
    isCheckLogin: boolean;
    isAuth: boolean;
    token: string|null;
    roles: string[]
    user: any
    service: any
}

const initialState: AuthState = {
    isInit: false,
    isCheckLogin: false,
    isAuth: false,
    token: null,
    roles: [],
    user: null,
    service: null,
};

export interface loginPayloadAction{
    token: string
}

export interface checkLoginPayloadAction{
    status: number,
    token: string,
}

interface AppJwtPayload extends JwtPayload{
    roles: string[],
    user: Maybe<any>,
    service: Maybe<any>,
}

export const checkLogin = createAsyncThunk(
    'auth/login/check',
    async (token: any) => {
        const response = await client.get('/api/me', {
            headers: {"Authorization": token ? `Bearer ${token}` : ""},
        });

        const jwtDecoded = jwtDecode<AppJwtPayload>(token);

        return {
            status: response.status,
            token: token,
            roles: jwtDecoded?.roles ?? [],
            user: jwtDecoded?.user ?? {},
            service: jwtDecoded?.service ?? {},
        };
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initAuth: (state) => {
            const token = window.localStorage.getItem('auth.token');
            // checkLogin('initAutha');
            // state.isInit = true;
            // if (token) {
            //     state.isAuth = true;
            //     state.isCheckLogin = false;
            //     state.token = token;
            // }
        },
        login: (state, action: PayloadAction<loginPayloadAction>) => {
            const jwtDecoded = jwtDecode<AppJwtPayload>(action.payload.token);

            state.isAuth = true;
            state.isCheckLogin = false;
            state.token = action.payload.token;
            state.roles = jwtDecoded?.roles ?? [];
            state.user = jwtDecoded?.user ?? {};
            state.service = jwtDecoded?.service ?? {};

            window.localStorage.setItem('auth.token',  action.payload.token);
        },
        logout: (state) => {
            state.isAuth = false;
            state.isCheckLogin = false;
            state.token = null;
            state.user = null;
            state.service = null;
            window.localStorage.removeItem('auth.token');
            window.history.pushState({}, "", "/");
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkLogin.pending, (state) => {
                state.isCheckLogin = true;
            })
            .addCase(checkLogin.fulfilled, (state, action: PayloadAction<checkLoginPayloadAction>) => {
                if (200 === action.payload.status) {
                    const jwtDecoded = jwtDecode<AppJwtPayload>(action.payload.token);

                    state.isInit = true;
                    state.isAuth = true;
                    state.isCheckLogin = false;
                    state.token = action.payload.token;
                    state.roles = jwtDecoded?.roles ?? [];
                    state.user = jwtDecoded?.user ?? {};
                    state.service = jwtDecoded?.service ?? {};

                    window.localStorage.setItem('auth.token',  action.payload.token);
                } else {
                    window.localStorage.removeItem('auth.token');
                }
            })
            .addCase(checkLogin.rejected, (state) => {
                window.localStorage.removeItem('auth.token');
                state.isCheckLogin = false;
            });
    },
})

// export const { removePopupProduct } = authSlice.actions

export const auth = (state: RootState): AuthState => state.auth;

export const { initAuth, login, logout } = authSlice.actions

export default authSlice.reducer
