import React from "react";
import {ProcessType} from "@process/types/process.types";
import {NumberFormat} from "@src/components";
import {Link} from "react-router-dom";
import {EstimateStatusKeyEnum} from "@estimate/types/estimate.enums";
import {Maybe} from "@src/types/global.types";
import {useSelector} from "react-redux";
import {processState} from "@process/store/processSlice";

interface ProcessEstimateProps {
}

export const ProcessEstimateComponent: React.FC<ProcessEstimateProps> = () => {
    const process: Maybe<ProcessType> = useSelector(processState);

    return (
        <>
            <dl className="dl-horizontal">
                <dt>Kosztorys:</dt>
                <dd>
                    {process.estimate ? (
                        <Link to={`/estimate/${process.estimate.id}`}>
                            {process.estimate.name}
                        </Link>
                    ) : (
                        <span className="text-muted">- - -</span>
                    )}
                </dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Status kosztorysu:</dt>
                <dd>
                    {process.estimate?.status?.name ?? <span className="text-muted">- - -</span>}
                </dd>
            </dl>
            {process.estimate?.status?.key === EstimateStatusKeyEnum.Accepted && (
                <dl className="dl-horizontal">
                    <dt>Data akceptacji:</dt>
                    <dd>{process.estimate?.acceptedAt}</dd>
                </dl>
            )}
            {process.estimate?.status?.key === EstimateStatusKeyEnum.Rejected && (
                <dl className="dl-horizontal">
                    <dt>Data rezygnacji:</dt>
                    <dd>{process.estimate?.rejectedAt}</dd>
                </dl>
            )}
            <dl className="dl-horizontal">
                <dt>Koszt usługi:</dt>
                <dd><NumberFormat value={process.servicePriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Koszt dostawy:</dt>
                <dd><NumberFormat value={process.shipmentPriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal">
                <dt>Koszt płatności:</dt>
                <dd><NumberFormat value={process.paymentPriceGross}/></dd>
            </dl>
            <dl className="dl-horizontal border-0">
                <dt>Koszt całkowity:</dt>
                <dd><NumberFormat value={process.totalPriceGross}/></dd>
            </dl>
        </>
    );
};

export default ProcessEstimateComponent;