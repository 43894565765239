import React, {ChangeEventHandler, FormEventHandler, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {EstimateFormErrorType, EstimateFormType} from "@estimate/types/estimate.types";
import {AxiosResponse} from "axios";
import {FormError} from "@src/components";

interface EstimateFormProps {
    initEstimateForm: EstimateFormType,
    saveEstimate: Function,
    afterSuccessSave: Function,
}

const EstimateFormComponent: React.FC<EstimateFormProps> = (
    {initEstimateForm, saveEstimate, afterSuccessSave}
) => {
    const [estimateForm, setEstimateForm] = useState<EstimateFormType>(initEstimateForm);
    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<EstimateFormErrorType>({});

    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = e.target.value;
        const name: string = e.target.name;

        setEstimateForm(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        saveEstimate(estimateForm)
            .then((response: AxiosResponse) => {
                afterSuccessSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }


    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label className="required">Nazwa kosztorysu:</Form.Label>
                <Form.Control
                    value={estimateForm.name ?? ''}
                    onChange={onChangeHandler}
                    name="name"
                    isInvalid={!!formErrors.name}
                />
                <FormError errors={formErrors.name} />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default EstimateFormComponent;