import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/pro-regular-svg-icons";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {ProcessFilterRequestType, ProcessType} from "@process/types/process.types";
import {NotFound} from "@src/components";
import {ProcessFilter} from "@process/components";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {getProcessCollection} from "@process/utils/axios";
import {Col, Row} from "react-bootstrap";
import ProcessTable from "@process/components/ProcessTable/ProcessTable";

interface ProcessListProps {
}

const ProcessListView: React.FC<ProcessListProps> = () => {
    const locationParams = useLocation().search;
    const [processCollection, setProcessCollection] = useState<ProcessType[]>([]);
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [processFilter, setProcessFilter] = useState<ProcessFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [isLoad, setLoad] = useState<boolean>(false);

    const fetchProcessCollection = () => {
        getProcessCollection(processFilter).then((paginationData: PaginationDataType<ProcessType>) => {
            setProcessCollection(paginationData.items);
            setPagination(paginationData.pagination);
        }).finally(() => {
            setLoad(true)
        });
    }

    useEffect(() => {
        const newProcessFilter: ProcessFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newProcessFilter) !== JSON.stringify(processFilter)) {
            setProcessFilter(newProcessFilter);
        }
    }, [locationParams])

    useEffect(() => {
        fetchProcessCollection();
    }, [processFilter]);

    return (
        <div className="view-process-list">
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Serwis
                        </div>
                        <h2 className="page-title">
                            Lista procesów serwisowych
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <span className="d-none d-sm-inline-">
                                <a href="#" className="btn btn-1">
                                    <FontAwesomeIcon icon={faSearch} fixedWidth={true}/> Szukaj
                                </a>
                            </span>
                            <Link to="/process/create" className="btn btn-primary">
                                <FontAwesomeIcon icon={faPlus} fixedWidth={true}
                                /> <span className="d-none d-sm-inline">
                                    Dodaj
                                </span>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <ProcessFilter processFilterRequest={processFilter}/>
                {processCollection.length === 0 && isLoad && (
                    <div className="text-center">
                        <NotFound/>
                    </div>
                )}

                {processCollection.length > 0 && (
                    <ProcessTable
                        processCollection={processCollection}
                        processFilter={processFilter}
                        pagination={pagination}
                    />
                )}
            </div>
        </div>
    )
};

export default ProcessListView;
