import React, {useEffect, useState} from "react";
import {UserCard} from "@user/components";
import {useParams} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {UserType} from "@user/types/user.types";
import {getUser} from "@user/utils/axios";
import {Maybe} from "@src/types/global.types";

interface UserViewProps {}

const UserCardView: React.FC<UserViewProps> = () => {
    const { id } = useParams();
    const [user, setUser] = useState<Maybe<UserType>>();

    useEffect(() => {
        getUser(id).then((user: UserType) => setUser(user));
    }, []);

    if (!user) {
        return null;
    }

    return (
        <div className="view-user">
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Użytkownicy / Karta użytkownika
                        </div>
                        <h2 className="page-title">
                            {user.firstName} {user.lastName}
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row>
                    <Col md={6} >
                        {user && <UserCard user={user} />}
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default UserCardView;