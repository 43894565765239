import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "@src/store";

export interface AppStateType {
    loading: boolean,
}

const initialState: AppStateType = {
    loading: false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAppLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    },
})

export const isAppLoading = (state: RootState): boolean => state.app.loading;
export const { setAppLoading } = appSlice.actions
export default appSlice.reducer