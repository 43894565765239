import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {OfferDiscountFormComponent, OfferItem, OfferItemForm} from "@offer/components";
import ReactModal from "react-modal";
import {downloadFile, toDateFormat, toDateTimeFormat} from "@src/helpers/utils.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPencil,
    faFilePdf,
    faSpinner,
    faXmark,
    faEnvelope,
    faScrewdriverWrench
} from "@fortawesome/pro-light-svg-icons";
import {NumberFormat} from "@src/components";
import {downloadOffer, getOffer, sendOfferEmail} from "@offer/utils/axios";
import {toast} from "react-toastify";
import {Button, Card, Col, Modal, Row, Table} from "react-bootstrap";
import {sendOfferEmailSwal} from "@offer/utils/swal";
import {Maybe} from "@src/types/global.types";
import {OfferItemType, OfferType} from "@offer/types/offer.types";
import {ProcessEstimateFormCommon} from "@process/components";
import {EstimateCreateComponent} from "@estimate/components";
import {faPlus, faSearch} from "@fortawesome/pro-regular-svg-icons";

const OfferCardView = () => {
    const { id: offerId } = useParams();
    const [isDownloading, setDownloading] = useState<boolean>(false);
    const [showOfferItemForm, setShowOfferItemForm] = useState<boolean>(false);
    const [offer, setOffer] = useState<Maybe<OfferType>>();
    const [offerItem, setOfferItem] = useState<Maybe<OfferItemType>>();
    const [loading, setLoading] = useState<boolean>(false);


    const showOfferItemFormHandler = (isOpen: boolean, offerItem?: OfferItemType) => {
        setShowOfferItemForm(isOpen);
        setOfferItem(offerItem);
    }

    const downloadOfferFile = () => {
        setDownloading(true);

        downloadOffer(offerId).then(response => {
            downloadFile(response.data, 'oferta.pdf')
        }).catch(error => {
            console.log('Error', error.message);
        }).finally(() => {
            setDownloading(false);
        })
    };

    const sendOfferToClientHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        sendOfferEmailSwal().then((result) => {
            if (result.isConfirmed) {
                sendOfferEmail(offer.id).then(() => {
                    toast.success("Oferta została wysłana");
                    fetchOffer();
                });
            }
        })
    }

    const fetchOffer = () => {
        setLoading(true);
        getOffer(offerId)
            .then((offer: OfferType) => setOffer(offer))
            .finally(() => setLoading(false));
    }

    useEffect(() => fetchOffer(), []);

    if (!offer) {
        return null;
    }

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Oferty / Karta oferty
                        </div>
                        <h2 className="page-title">
                            {offer.title}
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <Button variant="outline-primary" onClick={downloadOfferFile} disabled={isDownloading}>
                                {isDownloading
                                    ? <><FontAwesomeIcon icon={faSpinner} fixedWidth={true} spin={true}/> <span className="d-none d-sm-inline">pobieram...</span></>
                                    : <><FontAwesomeIcon icon={faFilePdf} fixedWidth={true}/> <span className="d-none d-sm-inline">Pobierz PDF</span></>
                                }
                            </Button>
                            <Button variant="outline-pink" onClick={sendOfferToClientHandler}>
                                <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}
                                /> <span className="d-none d-sm-inline">
                                    Wyślij do klienta
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row>
                    <Col md={6} className="mb-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <div className="card-title">Szczegóły oferty</div>
                                <div className="card-actions">
                                    <Link to={`/offer/edit/${offer.id}`} className="text-nowrap">
                                        <FontAwesomeIcon icon={faPencil}/> edytuj
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <dl className="dl-horizontal">
                                    <dt></dt>
                                    <dd><h3 className="m-0">{offer.title}</h3></dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>Data dodania:</dt>
                                    <dd>{toDateTimeFormat(offer.createdAt)}</dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>Status:</dt>
                                    <dd>{offer.status.name}</dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>Numer:</dt>
                                    <dd>{offer.number}</dd>
                                </dl>
                                <dl className="dl-horizontal border-0">
                                    <dt>Termin:</dt>
                                    <dd>{offer.termDate && toDateFormat(offer.termDate)}</dd>
                                </dl>
                            </div>
                        </div>
                    </Col>

                    <Col md={6} className="mb-3">
                        <div className="card h-100">
                            <div className="card-header">
                                <div className="card-title">Klient</div>
                                <div className="card-actions">
                                    <Link to={`/client/edit/${offer.client.id}`} className="text-nowrap">
                                        <FontAwesomeIcon icon={faPencil}/> edytuj
                                    </Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <dl className="dl-horizontal">
                                    <dt></dt>
                                    <dd>
                                        <h3 className="m-0">{offer.client.firstName} {offer.client.lastName}</h3>
                                    </dd>
                                </dl>
                                {offer.client.companyName && (
                                    <dl className="dl-horizontal">
                                        <dt>Firma:</dt>
                                        <dd>{offer.client.companyName}</dd>
                                    </dl>
                                )}
                                <dl className="dl-horizontal">
                                    <dt>NIP:</dt>
                                    <dd>{offer.client.nip}</dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>E-mail:</dt>
                                    <dd>{offer.client.email}</dd>
                                </dl>
                                <dl className="dl-horizontal">
                                    <dt>Telefon:</dt>
                                    <dd>{offer.client.phone}</dd>
                                </dl>
                                <dl className="dl-horizontal border-0">
                                    <dt>Adres:</dt>
                                    <dd>
                                        <div>{offer.client.street}</div>
                                        <div>{offer.client.postCode + ' ' + offer.client.city}</div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="d-grid gap-3 d-sm-flex gap-sm-0 mb-5 mt-3">
                    <Col sm={3} className="text-center">
                        <div>Wartość bazowa</div>
                        <div className="text-danger fs-1">
                            <NumberFormat value={offer.priceRegular} />
                        </div>
                    </Col>
                    <Col sm={3}  className="text-center">
                        <div>Wartość rabatu</div>
                        <div className="fs-1">
                            <NumberFormat value={offer.priceDiscount} />
                        </div>
                    </Col>
                    <Col sm={3}  className="text-center">
                        <div>Wartość rabatu %</div>
                        <div className="fs-1">
                            <NumberFormat value={offer.percentDiscount} extension="%"/>
                        </div>
                    </Col>
                    <Col sm={3}  className="text-center">
                        <div>Wartość oferty</div>
                        <div className="fs-1 text-success">
                            <NumberFormat value={offer.price} />
                        </div>
                    </Col>
                </Row>

                <div>

                    { offer.items.length > 0 && (
                        <OfferDiscountFormComponent id={offerId} refetch={fetchOffer} />
                    )}

                    {offer.items.length === 0 && (
                        <div className="alert alert-info">Nie dodano jeszcze produktów</div>
                    )}

                    {offer.items.length > 0 && (
                        <Card className="mb-3">
                            <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Produkt</th>
                                    <th className="text-end">Cena podstawowa</th>
                                    <th className="text-end">Rabat</th>
                                    <th className="text-end">Rabat%</th>
                                    <th className="text-end">Cena</th>
                                    <th className="text-end">Ilość</th>
                                    <th className="text-end">Wartość</th>
                                    <th style={{width: 0}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {offer.items.map((offerItem, index) => (
                                    <OfferItem
                                        key={offerItem.id}
                                        index={index}
                                        offerItem={offerItem}
                                        refetch={fetchOffer}
                                        showOfferItemFormHandler={showOfferItemFormHandler}
                                    />
                                ))}
                                </tbody>
                            </Table>
                        </Card>
                    )}

                    <button className="btn btn-outline-primary" onClick={() => showOfferItemFormHandler(true)}>dodaj
                        produkt
                    </button>

                    {showOfferItemForm && (
                        <Modal show={showOfferItemForm} onHide={() => showOfferItemFormHandler(false)}
                               size={"lg"} centered={true}
                               className="modal-blur"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title as="h5">Produkt oferty</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <OfferItemForm
                                    offer={offer}
                                    offerItem={offerItem ?? null}
                                    refetch={fetchOffer}
                                    showOfferItemFormHandler={showOfferItemFormHandler}
                                />
                            </Modal.Body>
                        </Modal>
                    )}

                </div>
            </div>
        </>
    );

}

export default OfferCardView;