import React, {ReactElement, useMemo} from "react";
import Table from "react-bootstrap/Table";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import {Card} from "react-bootstrap";
import {Pagination} from "@src/components";
import {getProcessColumn} from "@process/components/ProcessTable/ProcessTable.column";
import {ProcessTableProps} from "@process/components/ProcessTable/ProcessTable.types";
import {ProcessDropdownOptions} from "@process/components";

const ProcessTable: React.FC<ProcessTableProps> = (
    {processCollection, processFilter, pagination}
): ReactElement => {
    const tableColumns = useMemo(() => getProcessColumn(processFilter), [processFilter]);
    const table = useReactTable({
        data: processCollection,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <Card>
            <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} scope="col" {...header.column.columnDef.meta}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}
                            </th>
                        ))}
                        <th className="text-center" style={{width: 0}}>
                            <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                        </th>
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} {...cell.column.columnDef.meta}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                        <td className="text-center">
                            <ProcessDropdownOptions process={row.original} buttonVariant="outline-cyan"/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {pagination && (
                <Card.Footer>
                    <Pagination pagination={pagination}/>
                </Card.Footer>
            )}
        </Card>
    );
}

export default ProcessTable;