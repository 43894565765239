import React from "react";
import {
    ProcessItemPartRequestType,
    ProcessItemPartType,
    ProcessItemType,
    ProcessType
} from "@process/types/process.types";
import {AxiosResponse} from "axios";
import {ProcessItemPartForm} from "@process/components";
import {createProcessItemPart} from "@process/utils/axios";

interface ProcessItemPartCreateProps {
    process: ProcessType,
    processItem: ProcessItemType,
    afterSuccessSave: Function,
}

const ProcessItemPartCreateComponent: React.FC<ProcessItemPartCreateProps> = ({process, processItem, afterSuccessSave}) => {

    const initProcessItemPart: ProcessItemPartType = {
        isAttach: true,
    }

    const saveProcessItemPart = async (data: ProcessItemPartRequestType): Promise<AxiosResponse> => {
        return createProcessItemPart(process.id, processItem.id, data);
    }

    return (
        <>
            <ProcessItemPartForm
                processItemPart={initProcessItemPart}
                saveProcessItemPart={saveProcessItemPart}
                afterSuccessSave={afterSuccessSave}
            />
        </>
    );
}

export default ProcessItemPartCreateComponent;