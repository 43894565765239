import React, {ReactElement, useMemo} from "react";
import Table from "react-bootstrap/Table";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/pro-regular-svg-icons";
import {getOfferColumn} from "./OfferTable.column";
import {Card} from "react-bootstrap";
import {Pagination} from "@src/components";
import {OfferTableProps} from "@offer/components/OfferTable/OfferTable.types";
import {OfferDropdownOptions} from "@offer/components";

const OfferTable: React.FC<OfferTableProps> = (
    {offerCollection, offerFilter, pagination, refreshList}
): ReactElement => {
    const tableColumns = useMemo(() => getOfferColumn(offerFilter), [offerFilter]);
    const table = useReactTable({
        data: offerCollection,
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <Card>
            <Table responsive hover bordered className="table-vcenter card-table card-table-options">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} scope="col" {...header.column.columnDef.meta}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}
                            </th>
                        ))}
                        <th scope="col" className="text-center">
                            <a href="#"><FontAwesomeIcon icon={faGear}/></a>
                        </th>
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}  {...cell.column.columnDef.meta}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                        <td className="text-center">
                            <OfferDropdownOptions
                                offer={row.original}
                                fetchOffers={refreshList}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {pagination && (
                <Card.Footer>
                    <Pagination pagination={pagination}/>
                </Card.Footer>
            )}
        </Card>
    );
}

export default OfferTable;