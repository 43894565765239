import React, {FormEventHandler, useState} from "react";
import {
    ProcessFileErrorType,
    ProcessFileType,
    ProcessType
} from "@process/types/process.types";
import {Button, Form} from "react-bootstrap";
import {CommonErrorType} from "@src/types/global.types";
import {DictionarySelect} from "@src/modules/dictionary/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {updateProcessFile} from "@process/utils/axios";
import {toast} from "react-toastify";
import {mapProcessFileToProcessFileFormType} from "@process/utils/mapper";
import {DictionaryType} from "@dictionary/types/dictionary.types";

interface ProcessFileFormProps {
    process: ProcessType,
    processFile: ProcessFileType,
    refresh: Function,
    setShowProcessFileForm: Function,
}

const ProcessFileFormComponent: React.FC<ProcessFileFormProps> = ({process, processFile: initProcessFile, refresh, setShowProcessFileForm}) => {

    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<ProcessFileErrorType>({});
    const [processFile, setProcessFile] = useState<ProcessFileType>(initProcessFile);

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setProcessFile(prevState => (
            {...prevState, file: {...prevState.file, name: value}}
        ));
        removeFromFormErrors('name');
    }

    const onCategoryChangeHandler = (category: DictionaryType) => {
        setProcessFile(prevState => ({...prevState, category}));
        removeFromFormErrors('category');
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSending(true);
        updateProcessFile(process.id, processFile.file.id, mapProcessFileToProcessFileFormType(processFile))
            .then(() => {
                toast.success("Zapisano");
                setShowProcessFileForm();
                refresh();
            })
            .finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label>Nazwa pliku:</Form.Label>
                <Form.Control type="text" name="name"
                      value={processFile?.file?.name ?? ''}
                      className={formErrors.name ? ' is-invalid' : ''}
                      onChange={onNameChangeHandler}
                />
                {formErrors.name && (
                    <Form.Control.Feedback type="invalid">
                        {formErrors.name.map((error: CommonErrorType, key: React.Key) => <div
                            key={key}>{error.message}</div>)}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Kategoria:</Form.Label>
                <DictionarySelect
                    name="category"
                    model="ProcessFileCategory"
                    onChange={onCategoryChangeHandler}
                    value={processFile.category}
                />
            </Form.Group>

            <hr className="mb-4 mt-4"/>

            <Button size="lg" type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
};

export default ProcessFileFormComponent;