import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";
import NotFound from "@src/assets/images/svg/not-found.svg";

interface FormErrorProps {
}

const NotFoundComponent:React.FC<FormErrorProps> = () => {

    return (
        <div>
            <h2 className="section-title">Brak wyników wyszukiwania</h2>
            <div className="section-description text-center m-auto" style={{width: 400, maxWidth: '100%'}}>
                <div className="d-md-flex gap-3">
                    <div>
                        <FontAwesomeIcon icon={faCircleInfo} size="2x" className="text-cyan" />
                    </div>
                    <div className="text-md-start text-center">
                        Jeśli szukasz klienta po numerze telefonu lub NIPie,
                        nie&nbsp;musisz podawać spacji i kresek w szukanej frazie.
                        <div>Wyszukuj podając tylko liczby, np: 5252615910</div>
                    </div>
                </div>
            </div>
            <img src={NotFound} alt="" style={{width: 400, maxWidth: '100%'}} />
        </div>
    );
};

export default NotFoundComponent;