import React from "react";

import { Outlet} from "react-router-dom";

const AppSimpleLayout: React.FC = (props) => {

    return (
        <div className="app-simple-layout">
            <Outlet/>
        </div>
    );
}

export default AppSimpleLayout;