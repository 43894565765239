import {SortableColumn} from "@src/components";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {UserFilterRequestType, UserType} from "@user/types/user.types";
import {Link} from "react-router-dom";

const columnHelper = createColumnHelper<UserType>()

export const getUserColumn = (userFilter: UserFilterRequestType) => {
    return [
        columnHelper.accessor(user => user, {
            id: 'firstName',
            header: () => <SortableColumn label="Imię" orderKey="firstName" params={userFilter}/>,
            cell: info => <Link to={'/user/' + info.getValue().id}>{info.getValue().firstName}</Link>
        }),
        columnHelper.accessor(user => user, {
            id: 'lastName',
            header: () => <SortableColumn label="Nazwisko" orderKey="lastName" params={userFilter}/>,
            cell: info => <Link to={'/user/' + info.getValue().id}>{info.getValue().lastName}</Link>
        }),
        columnHelper.accessor(user => user.email, {
            id: 'email',
            header: () => <SortableColumn label="E-mail" orderKey="email" params={userFilter}/>,
        }),
        columnHelper.accessor(user => user.phone, {
            id: 'phone',
            header: 'Telefon',
        }),
        columnHelper.accessor(user => user.department, {
            id: 'department',
            header: 'Oddział',
            cell: info => info.getValue()?.name
        }),
    ]
}