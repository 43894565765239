import React from "react";
import {UserForm} from "@user/components";
import {Col, Row} from "react-bootstrap";

interface UserCreateProps {}

const UserCreateView: React.FC<UserCreateProps> = () => {
    return (
        <div className="view-user-create">

            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Użytkownicy
                        </div>
                        <h2 className="page-title">
                            Nowy użytkownik
                        </h2>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <Row>
                    <Col sm md={{span: 8, offset: 2}} >
                        <UserForm user={{}}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default UserCreateView;
