import React, {FormEventHandler, ReactElement, useState} from "react";
import {ProcessErrorType, ProcessStatusAssignErrorType, ProcessType} from "@process/types/process.types";
import {mapProcessTypeToProcessInvoiceNumberRequestType} from "@process/utils/mapper";
import {toast} from "react-toastify";
import {Button, Form} from "react-bootstrap";
import {FormError} from "@src/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-light-svg-icons";
import {updateProcessInvoiceNumber} from "@process/utils/axios";

interface ProcessInvoiceNumberFormProps {
    process: ProcessType,
    afterSave: Function,
}

const ProcessInvoiceNumberFormComponent: React.FC<ProcessInvoiceNumberFormProps> = ({
    process: processInit, afterSave
}): ReactElement => {
    const [process, setProcess] = useState<ProcessType>(processInit);
    const [formErrors, setFormErrors] = useState<ProcessStatusAssignErrorType>({});
    const [isSending, setSending] = useState<boolean>(false)

    const removeFromFormErrors = (key: string) => {
        setFormErrors(prevState => {
            let newState = {...prevState};
            delete newState[key];
            return newState;
        })
    }

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setProcess(prevState => ({...prevState, [name]: value}));
        removeFromFormErrors(name);
    }


    const onSubmitHandler: FormEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSending(true);

        updateProcessInvoiceNumber(process.id, mapProcessTypeToProcessInvoiceNumberRequestType(process))
            .then(response => {
                toast.success("Numer dokumentu został zapisany");
                afterSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            }).finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
                <Form.Label>Numer faktury lub paragonu</Form.Label>
                <Form.Control type="text" name="invoiceNumber"
                      value={process.invoiceNumber ?? ''}
                      onChange={onChangeHandler}
                      isInvalid={!!formErrors.invoiceNumber}
                />
                <FormError errors={formErrors?.invoiceNumber} />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending}>
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>

        </Form>
    )
}

export default ProcessInvoiceNumberFormComponent;
