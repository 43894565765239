import {NumberFormat, SortableColumn} from "@src/components";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {ProcessFilterRequestType, ProcessType} from "@process/types/process.types";
import {Link} from "react-router-dom";
import {ProcessSalonStatusKeyEnum} from "@process/types/process.enums";
import {toDateTimeFormat} from "@src/helpers/utils.helper";
import {EstimateStatusKeyEnum} from "@estimate/types/estimate.enums";

const columnHelper = createColumnHelper<ProcessType>()

export const getProcessColumn = (processFilter: ProcessFilterRequestType) => {
    return [
        columnHelper.accessor(process => process.date, {
            id: 'date',
            header: () => <SortableColumn label="Data" orderKey="createdAt" params={processFilter}/>,
        }),
        columnHelper.accessor(process => process, {
            id: 'number',
            header: () => <SortableColumn label="Numer" orderKey="number" params={processFilter}/>,
            cell: info => <Link to={`/process/${info.getValue().id}`}>{info.getValue().number}</Link>
        }),
        columnHelper.accessor(process => process, {
            id: 'title',
            header: () => <SortableColumn label="Tytuł" orderKey="title" params={processFilter}/>,
            cell: info => {
                const process = info.getValue();
                return (
                    <div className="d-flex justify-content-between">
                        <Link to={`/process/${process.id}`}>{process.title}</Link>
                        {process.hasItemSubstitute && (
                            <span>
                                <span className="badge bg-purple-lt">ZASTĘPCZY</span>
                            </span>
                        )}
                    </div>
                )
            }
        }),
        columnHelper.accessor(process => process, {
            id: 'salonStatus',
            header: () => <SortableColumn label="Status salonu" orderKey="salonStatus" params={processFilter}/>,
            cell: info => {
                const process = info.getValue();
                return (
                    <>
                        <div>{process.salonStatus.name}</div>
                        {(process.salonStatus.key === ProcessSalonStatusKeyEnum.Done && process.closedAt) && (
                            <div className="small text-muted">{toDateTimeFormat(process.closedAt)}</div>
                        )}
                    </>
                )
            },
            meta: {
                className: 'text-center'
            }
        }),
        columnHelper.accessor(process => process, {
            id: 'serviceStatus',
            header: () => <SortableColumn label="Status serwisu" orderKey="serviceStatus" params={processFilter}/>,
            cell: info => {
                const process = info.getValue();
                return (process.serviceStatus?.name ?? <span className="text-muted">- - -</span>)
            },
            meta: {
                className: 'text-center'
            }
        }),
        columnHelper.accessor(process => process.user, {
            id: 'user',
            header: () => <SortableColumn label="Przypisane do" orderKey="user" params={processFilter}/>,
            cell: info => `${info.getValue().firstName} ${info.getValue().lastName}`
        }),
        columnHelper.accessor(process => process.customer, {
            id: 'customer',
            header: () => <SortableColumn label="Klient" orderKey="customer" params={processFilter}/>,
            cell: info => {
                const customer = info.getValue();
                return customer.companyName
                    ? customer.companyName
                    : `${customer.firstName ?? ''} ${customer.lastName ?? ''}`
            },
        }),
        columnHelper.accessor(process => process, {
            id: 'totalPriceGross',
            header: () => <SortableColumn label="Wycena" orderKey="totalPriceGross" params={processFilter}/>,
            cell: info => {
                const process = info.getValue();
                return (
                    <>
                        {process.estimate?.status?.key === EstimateStatusKeyEnum.Accepted && (
                            <span className="badge bg-success me-1"></span>
                        )}
                        {process.estimate?.status?.key === EstimateStatusKeyEnum.Rejected && (
                            <span className="badge bg-danger me-1"></span>
                        )}
                        {process.estimate?.status?.key === EstimateStatusKeyEnum.Sent && (
                            <span className="badge bg-primary me-1"></span>
                        )}

                        {process.estimate
                            ? <Link to={`/estimate/${process.estimate.id}`}><NumberFormat
                                value={process.totalPriceGross}/></Link>
                            : <NumberFormat value={process.totalPriceGross}/>
                        }
                    </>
                )
            },
            meta: {
                className: 'text-end'
            }
        }),
    ]
}