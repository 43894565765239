import React from "react";
import {
    ProcessItemPartRequestType,
    ProcessItemPartType,
    ProcessItemType,
    ProcessType
} from "@process/types/process.types";
import {AxiosResponse} from "axios";
import {ProcessItemPartForm} from "@process/components";
import {updateProcessItemPart} from "@process/utils/axios";

interface ProcessItemPartCreateProps {
    process: ProcessType,
    processItem: ProcessItemType,
    processItemPart: ProcessItemPartType,
    afterSuccessSave: Function,
}

const ProcessItemPartEditComponent: React.FC<ProcessItemPartCreateProps> = ({process, processItem, processItemPart, afterSuccessSave}) => {

    const saveProcessItemPart = async (data: ProcessItemPartRequestType): Promise<AxiosResponse> => {
        return updateProcessItemPart(process.id, processItem.id, processItemPart.id, data);
    }

    return (
        <ProcessItemPartForm
            processItemPart={processItemPart}
            saveProcessItemPart={saveProcessItemPart}
            afterSuccessSave={afterSuccessSave}
        />
    );
}

export default ProcessItemPartEditComponent;