import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {parseFilterParamsFromUrl} from "@src/helpers/utils.helper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus, faSearch} from '@fortawesome/pro-regular-svg-icons'
import {NotFound} from "@src/components";
import {OfferFilterRequestType, OfferType} from "@offer/types/offer.types";
import {OfferFilter, OfferTable} from "@offer/components";
import {Maybe, PaginationDataType, PaginationType} from "@src/types/global.types";
import {getOfferCollection} from "@offer/utils/axios";
import {Col, Row} from "react-bootstrap";

interface OfferListProps {
}

const OfferListView: React.FC<OfferListProps> = () => {
    const locationParams = useLocation().search;
    const [offerList, setOfferList] = useState<Maybe<OfferType[]>>([]);
    const [pagination, setPagination] = useState<Maybe<PaginationType>>();
    const [offerFilter, setOfferFilter] = useState<OfferFilterRequestType>(parseFilterParamsFromUrl(locationParams));
    const [isLoad, setLoad] = useState<boolean>(false);

    useEffect(() => {
        const newOfferFilter: OfferFilterRequestType = parseFilterParamsFromUrl(locationParams);
        if (JSON.stringify(newOfferFilter) !== JSON.stringify(offerFilter)) {
            setOfferFilter(newOfferFilter);
        }
    }, [locationParams])

    const fetchOfferCollection = () => {
        getOfferCollection(offerFilter).then((paginationData: PaginationDataType<OfferType>) => {
            setOfferList(paginationData.items);
            setPagination(paginationData.pagination);
            setLoad(true);
        });
    }

    useEffect(fetchOfferCollection, [offerFilter]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [offerList]);

    return (
        <>
            <div className="page-header">
                <Row className="row g-2 align-items-center">
                    <Col className="col">
                        <div className="page-pretitle">
                            Oferty
                        </div>
                        <h2 className="page-title">
                            Lista ofert
                        </h2>
                    </Col>
                    <Col className="col-auto ms-auto">
                        <div className="btn-list">
                            <span className="d-none d-sm-inline-">
                                <a href="#" className="btn btn-1">
                                    <FontAwesomeIcon icon={faSearch} fixedWidth={true}/> Szukaj
                                </a>
                            </span>
                            <Link to="/offer/create" className="btn btn-primary">
                                <FontAwesomeIcon icon={faPlus} fixedWidth={true}
                                /> <span className="d-none d-sm-inline">
                                    Dodaj ofertę
                                </span>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="page-body">
                <OfferFilter clientFilterRequest={offerFilter}/>
                {offerList.length === 0 && isLoad && (
                    <div className="text-center">
                        <NotFound/>
                    </div>
                )}

                {offerList.length > 0 && (
                    <OfferTable
                        offerCollection={offerList}
                        offerFilter={offerFilter}
                        pagination={pagination}
                        refreshList={fetchOfferCollection}
                    />
                )}
            </div>
        </>
    );
}

export default OfferListView;