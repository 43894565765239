import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {authReducer} from "@auth/store";
import {processReducer} from "@process/store";
import {appReducer} from "@layout/store";

export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        process: processReducer
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
