import React from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {ProcessFileType, ProcessType} from "@process/types/process.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faEllipsisVertical,
    faPen,
    faBoxArchive,
} from "@fortawesome/pro-regular-svg-icons";
import {AxiosResponse} from "axios";
import {downloadFile, toDateTimeFormat} from "@src/helpers/utils.helper";
import {archiveFile, getFile} from "@file/utils/axios";
import {FileSize} from "@src/components";
import {archiveFileSwal} from "@process/utils/swal";
import {SweetAlertResult} from "sweetalert2";
import ProcessFileForm from "./ProcessFileForm.component";

interface ProcessFileProps {
    process: ProcessType,
    processFile: ProcessFileType,
    refresh: Function,
}

const ProcessFileComponent: React.FC<ProcessFileProps> = ({process, processFile, refresh}) => {

    const [showProcessFileForm, setShowProcessFileForm] = React.useState<boolean>(false);

    const downloadProcessFile = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();

        getFile(processFile.file.id)
            .then((response: AxiosResponse<Blob>) => {
                const fileName = response.headers["content-disposition"]?.split(";")[1]?.replace(/filename=/g, "")
                    ?? processFile.file.filename;

                downloadFile(response.data, fileName);
            })
    }

    const archiveFileHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        archiveFileSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                archiveFile(processFile.file.id).then(() => {
                    refresh();
                })
            }
        })
    }

    const showProcessFileFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowProcessFileForm(prevState => !prevState);
    }

    return (
        <tr key={processFile.id}>
            <td>
                <a href="#" onClick={downloadProcessFile}>
                    {processFile.file.name}
                </a>
            </td>
            <td className="text-center">{processFile.category?.name ?? ''}</td>
            <td>{processFile.file.createdBy.firstName} {processFile.file.createdBy.lastName}</td>
            <td className="text-center">{toDateTimeFormat(processFile.file.updatedAt)}</td>
            <td className="text-center">{processFile.file.extension}</td>
            <td className="text-end"><FileSize size={processFile.file.size} /></td>
            <td className="text-end">
                <Dropdown>
                    <Dropdown.Toggle variant={"outline-cyan"}>
                        <FontAwesomeIcon icon={faEllipsisVertical}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={downloadProcessFile}>
                            <FontAwesomeIcon icon={faDownload} fixedWidth /> Pobierz
                        </Dropdown.Item>
                        <Dropdown.Item onClick={showProcessFileFormHandler}>
                            <FontAwesomeIcon icon={faPen} fixedWidth /> Edytuj
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            className="text-danger"
                            onClick={archiveFileHandler}
                        >
                            <FontAwesomeIcon icon={faBoxArchive} fixedWidth /> Archiwizuj
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {showProcessFileForm && (
                    <Modal show={showProcessFileForm} onHide={() => setShowProcessFileForm(false)}
                           size={"lg"} centered={true}
                           className="modal-blur"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title as="h5">Edycja pliku</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ProcessFileForm
                                process={process}
                                processFile={processFile}
                                refresh={refresh}
                                setShowProcessFileForm={() => setShowProcessFileForm(false)}
                            />
                        </Modal.Body>
                    </Modal>
                )}
            </td>
        </tr>
    );
};

export default ProcessFileComponent;