import React from "react";
import {EstimateType} from "@estimate/types/estimate.types";
import {NumberFormat} from "@src/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faPen, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {Button, Dropdown, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {faTrash} from "@fortawesome/pro-light-svg-icons";
import {SweetAlertResult} from "sweetalert2";
import {deleteEstimateItemSwal} from "@estimate/utils/swal";
import {deleteEstimateItem, updateEstimateItem} from "@estimate/utils/axios";
import {toast} from "react-toastify";
import ReactModal from "react-modal";
import {EstimateItemFormComponent} from "@estimate/components";
import {EstimateItemRequestType, EstimateItemType} from "@estimate/types/estimateItem.types";
import {AxiosResponse} from "axios";

interface EstimateItemListProps {
    index: number
    estimate: EstimateType
    estimateItem: EstimateItemType
    fetchEstimate: Function
}

const EstimateItemListLineComponent: React.FC<EstimateItemListProps> = (
    {index, estimate, estimateItem, fetchEstimate}
) => {
    const [showEstimateItemForm, setShowEstimateItemForm] = React.useState<boolean>(false);

    const deleteEstimateItemHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        deleteEstimateItemSwal().then((result: SweetAlertResult) => {
            if (result.isConfirmed) {
                deleteEstimateItem(estimate.id, estimateItem.id).then(() => {
                    toast.success("Pozycja kosztorysu została usunięta.");
                    fetchEstimate();
                })
            }
        })
    }

    const showEstimateItemFormHandler = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        setShowEstimateItemForm(prevState => !prevState);
    }

    const afterSuccessSave = (): void => {
        setShowEstimateItemForm(false);
        fetchEstimate();
    }

    const saveEstimateItem = async (data: EstimateItemRequestType): Promise<AxiosResponse> => {
        return updateEstimateItem(estimate.id, estimateItem.id, data);
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{estimateItem.sku}</td>
            <td>
                {estimateItem.name}
                {estimateItem.note && (
                    <div className="small text-muted"><em>{estimateItem.note}</em></div>
                )}
            </td>
            <td className="text-end">{estimateItem.quantity}</td>
            <td className="text-end">{estimateItem.unit.name}</td>
            <td className="text-end"><NumberFormat value={estimateItem.unitPriceGross}/></td>
            <td className="text-end"><NumberFormat value={estimateItem.totalPriceGross}/></td>
            <td style={{width: 0}}>
                <Dropdown>
                    <Dropdown.Toggle variant={"outline-cyan"}>
                        <FontAwesomeIcon icon={faEllipsisVertical} fixedWidth={true}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/*<Dropdown.Item as={Link} to={'/process/' + estimateItem.id}>*/}
                        {/*    <FontAwesomeIcon icon={faEye} fixedWidth/> Karta*/}
                        {/*</Dropdown.Item>*/}
                        <Dropdown.Item onClick={showEstimateItemFormHandler}>
                            <FontAwesomeIcon icon={faPen} fixedWidth/> Edycja
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item as={Link} to="#" className="text-danger" onClick={deleteEstimateItemHandler}>
                            <FontAwesomeIcon icon={faTrash} fixedWidth/> Usuń
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Modal show={showEstimateItemForm} onHide={() => setShowEstimateItemForm(false)}
                       size={"lg"} centered={true}
                       className="modal-blur"
                >
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">Edycja pozycji kosztorysu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EstimateItemFormComponent
                            estimateItem={estimateItem}
                            saveEstimateItem={saveEstimateItem}
                            afterSuccessSave={afterSuccessSave}
                        />
                    </Modal.Body>
                </Modal>
            </td>
        </tr>
    )
}

export default EstimateItemListLineComponent;