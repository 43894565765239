import {UserFilterRequestType, UserFilterType, UserRequestType, UserType} from "@user/types/user.types";

export const mapUserToRequestType = (user: UserType): UserRequestType => {
    return {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        jobPosition: user.jobPosition,
        department: user?.department?.id ?? null,
    };
}

export const mapUserFilterRequestToUserFilterType = (
    userFilterRequestType: UserFilterRequestType
): UserFilterType => {
    return {
        search: userFilterRequestType?.search ?? '',
        page: userFilterRequestType.page ?? 1,
        sort: userFilterRequestType.sort ?? [],
    }
}