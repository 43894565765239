import React, {ChangeEventHandler, FormEventHandler, ReactElement, useState} from "react";
import {ProcessItemSubstituteErrorType, ProcessItemSubstituteType} from "@process/types/processItem.types";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormError} from "@src/components";
import { UserSelect } from "@user/components";
import {UserType} from "@user/types/user.types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {mapProcessItemSubstituteTypeToRequestType} from "@process/utils/mapper/processItem";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";


interface ProcessItemSubstituteFormProps {
    processItemSubstitute: ProcessItemSubstituteType,
    saveProcessItemSubstitute: Function
    afterSuccessSave: Function
}


const ProcessItemSubstituteFormComponent: React.FC<ProcessItemSubstituteFormProps> = ({
    processItemSubstitute: initProcessItemSubstitute, saveProcessItemSubstitute, afterSuccessSave
}): ReactElement => {
    const [processItemSubstitute, setProcessItemSubstitute] = useState<ProcessItemSubstituteType>(initProcessItemSubstitute);
    const [isSending, setSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<ProcessItemSubstituteErrorType>({});


    const onChangeHandler: ChangeEventHandler<HTMLElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name: string = e.target.name;
        const type: string = e.target.type;
        let value: string|boolean;

        if ('checkbox' === type) {
            value = e.target.checked;
        } else {
            value = e.target.value;
        }

        setProcessItemSubstitute(prevState => {
            return {...prevState, [name]: value};
        })
    }

    const onProcessUserChangeHandler = (user: UserType) => {
        setProcessItemSubstitute((prevState: ProcessItemSubstituteType) => {
            return {...prevState, user: user}
        });
    }

    const onSubmitHandler: FormEventHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);
        saveProcessItemSubstitute(mapProcessItemSubstituteTypeToRequestType(processItemSubstitute))
            .then(() => {
                toast.success("Urządzenie zastępcze zostało zapisane");
                afterSuccessSave();
            })
            .catch(error => {
                if (400 === error.response.status) {
                    const responseErrors = error.response?.data?.errors || {};
                    setFormErrors(responseErrors);
                } else {
                    console.log('Error', error.message);
                }
            })
            .finally(() => setSending(false))
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="required text-md-end">Nazwa/Model:</Form.Label>
                <Col sm={9}>
                    <Form.Control
                        value={processItemSubstitute.name ?? ''}
                        onChange={onChangeHandler}
                        name="name"
                        isInvalid={!!formErrors.name}
                    />
                    <FormError errors={formErrors.name} />
                </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="text-md-end">Numer seryjny:</Form.Label>
                <Col sm={9}>
                    <Form.Control
                        value={processItemSubstitute.serialNumber ?? ''}
                        onChange={onChangeHandler}
                        name="serialNumber"
                        isInvalid={!!formErrors.serialNumber}
                    />
                    <FormError errors={formErrors.serialNumber} />
                </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="required text-md-end">Data wydania:</Form.Label>
                <Col sm={9}>
                    <Form.Control
                        type="date"
                        name="issueDate"
                        value={processItemSubstitute.issueDate ?? ''}
                        onChange={onChangeHandler}
                        isInvalid={!!formErrors.issueDate}
                    />
                    <FormError errors={formErrors?.issueDate} />
                </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className={"text-md-end"}>Data zwrotu:</Form.Label>
                <Col sm={9}>
                    <Form.Control
                        type="date"
                        name="returnDate"
                        value={processItemSubstitute.returnDate ?? ''}
                        onChange={onChangeHandler}
                        isInvalid={!!formErrors.returnDate}
                    />
                    <FormError errors={formErrors?.returnDate} />
                </Col>
            </Form.Group>

            <Form.Group className="mb-3" as={Row}>
                <Form.Label column className="required text-md-end">Przypisane do:</Form.Label>
                <Col sm={9}>
                    <UserSelect
                        value={processItemSubstitute.user}
                        onChange={onProcessUserChangeHandler}
                        isInvalid={!!formErrors.user}
                    />
                    <FormError errors={formErrors?.user} />
                </Col>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Notatka</Form.Label>
                <Form.Control as="textarea" name="note" rows={3}
                              onChange={onChangeHandler}
                              value={processItemSubstitute.note ?? ''}
                />
                <FormError errors={formErrors?.note} />
            </Form.Group>

            <Button type="submit" variant="primary" disabled={isSending} className="mt-3">
                {isSending ? (
                    <><FontAwesomeIcon icon={faSpinnerThird} spin={true}/> wysyłam</>
                ) : 'Zapisz'}
            </Button>
        </Form>
    );
}

export default ProcessItemSubstituteFormComponent;
